import React from "react";
import './dashboard.css';


const Dashboard = () => {


  return (
    <div class="dashboard-container">
      <p>Welcome to Assistant Panel Dashboard</p>
    </div>
  );
};

export default Dashboard;
