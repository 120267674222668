import { logout } from "../Redux-store/authSlice"; // Action to set user state
import { store } from "../Redux-store/store"; // Import the store
//import toast from "react-hot-toast";  // Toast for showing notifications
import { useNavigate } from "react-router-dom"; // Use navigate for redirection
import { showToast } from "../services/toastifyservices";

export function handleSession(error, navigate) {
  if (error?.status === "token_expired") {
    showToast("error", error.message);
    localStorage.clear();
    store.dispatch(logout());
    navigate("/Signin", { replace: true });
  }
}
