import React, { useState } from "react";
import { useFormik } from 'formik';
import { Table, Button } from "react-bootstrap";



const ManageClaim = () => {
    let servicetableRow = {
        serviceDateStart: "",
        serviceDateEnd: "",
        pos: "",
        emg: "",
        procedure: "",
        modifiers: ["", "", "", ""],
        diagRef: "",
        charges: "",
        units: "",
    }
    const [isVisible, setIsVisible] = useState(false);
    const [rows, setRows] = useState([]);
    const ToggleDiv = (e) => {
        setIsVisible(e.target.checked);
    };

    const formik = useFormik({
        initialValues: {
            playerName: "",
            playerId: "",
            playertype: "",
            insuredidnumber: "",
            showplayeraddress: "",

            pat_city: "",
            pat_state: "",
            pat_zip: "",
            pat_country: "",
            pat_employment: "",
            ins_city: "",
            ins_state: "",
            ins_zip: "",
            ins_country: "",

            otherInsNameL: '',
            otherInsNameF: '',
            otherInsNameM: '',
            insuredpolicgroup: '',
            secondaryinsuredpolicy: '',
            dob: '',
            sex: '',
            insuredSex: '',
            insureddob: '',
            insuredpdob: '',
            patientconditionrelatedstate: '',
            patientconditionrelatedcountry: '',
            otherincident: '',

            other_ins_payment_date: '',
            gen_cond_code: '',
            ins_plan: '',

            other_payer_name: '',
            other_payerid: '',
            other_claimfilingcode: '',
            icn_dcn_2: '',
            other_payer_addr_1: '',
            other_payer_addr_2: '',
            other_payer_city: '',
            other_payer_state: '',
            other_payer_zip: '',
            other_ins_medicare_code: '',
            other_pat_rel: '',
            other_ins_plan: '',
            other_ins_group: '',
            supv_prov_name_l: '',
            supv_prov_name_f: '',
            supv_prov_name_m: '',
            other_payer_ins_type: [],

            //table
            serviceDateStart: "",
            serviceDateEnd: "",
            pos: "",
            emg: "",
            procedure: "",
            modifiers: ["", "", "", ""], // For the 4 modifier inputs
            diagRef: "",
            charges: "",
            units: "",
        },
        onSubmit: (values) => {
            alert(`Player Name: ${values.playerName}, Player Id: ${values.playerId}`);
        },


    });

    // Function to add a new row
    const addNewRow = () => {
        const newRow = { ...servicetableRow }; // Create a new empty row
        setRows([...rows, newRow]); // Add it to the rows
    };

    // Function to handle updates to row data
    const handleRowChange = (index, field, value) => {
        const updatedRows = rows.map((row, i) =>
            i === index ? { ...row, [field]: value } : row
        );
        setRows(updatedRows);
    };

    // Function to handle updates for modifiers (array field)
    const handleModifierChange = (rowIndex, modIndex, value) => {
        const updatedRows = rows.map((row, i) => {
            if (i === rowIndex) {
                const updatedModifiers = [...row.modifiers];
                updatedModifiers[modIndex] = value;
                return { ...row, modifiers: updatedModifiers };
            }
            return row;
        });
        setRows(updatedRows);
    };

    return (
        <>
            <div className="mt-3 p-2 md:p-10 bg-gray-200">
                <div className="card">
                    <div className="card-header">
                        <span className="text-start font-bold ml-4 p-2 headingtext">Manage Claim</span>

                    </div>
                    <div className="card-body">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-md-4">
                                    <label
                                        htmlFor="playerName"
                                        className="form-label font-bold"
                                    >
                                        Player Name
                                    </label>
                                    <input
                                        type="text"
                                        id="playerName"
                                        name="playerName"
                                        className="form-control"
                                        value={formik.values.playerName}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="playerId"
                                                className="form-label font-bold"
                                            >
                                                Player Id
                                            </label>
                                            <input
                                                type="text"
                                                id="playerId"
                                                name="playerId"
                                                className="form-control"
                                                value={formik.values.playerId}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label class="form-label font-bold" for="flexCheckDefault">
                                                Show Player Address
                                            </label>
                                            <input className="form-check-input" type="checkbox" value={formik.values.showplayeraddress} id="showplayeraddress" onClick={ToggleDiv} />
                                            <select
                                                className="form-select "
                                                id="playertype"
                                                value={formik.values.playertype}

                                            >
                                                <option value=""> </option>
                                                <option value="Primary">Primary</option>
                                                <option value="Secondary">Secondary</option>
                                                <option value="Teritary">Teritary</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label
                                        htmlFor="playerName"
                                        className="form-label font-bold"
                                    >
                                        Insured Id Number
                                    </label>
                                    <input
                                        type="text"
                                        id="playerName"
                                        name="insuredidnumber"
                                        className="form-control"
                                        value={formik.values.insuredidnumber}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                            {isVisible && (
                                <div>
                                    <div className="row mt-2">
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="playerName"
                                                className="form-label font-bold"
                                            >
                                                Player Address 1
                                            </label>
                                            <input
                                                type="text"
                                                id="playerAddress1"
                                                name="playerAddress1"
                                                placeholder="playerAddress1"
                                                className="form-control"
                                                value={formik.values.playerAddress1}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="playerName"
                                                className="form-label font-bold"
                                            >
                                                player Address 2
                                            </label>
                                            <input
                                                type="text"
                                                id="playerAddress2"
                                                name="playerAddress2"
                                                placeholder="player Address 2"
                                                className="form-control"
                                                value={formik.values.playerAddress2}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <label
                                                        htmlFor="playerName"
                                                        className="form-label font-bold"
                                                    >
                                                        City
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="City"
                                                        name="City"
                                                        placeholder="City"
                                                        className="form-control"
                                                        value={formik.values.City}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <label
                                                        htmlFor="playerName"
                                                        className="form-label font-bold"
                                                    >
                                                        ST
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id=" ST"
                                                        name=" ST"
                                                        placeholder=" ST"
                                                        className="form-control"
                                                        value={formik.values.ST}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label
                                                        htmlFor="playerName"
                                                        className="form-label font-bold"
                                                    >
                                                        ZIP
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="ZIP"
                                                        name="ZIP"
                                                        placeholder="ZIP"
                                                        className="form-control"
                                                        value={formik.values.ZIP}
                                                        onChange={formik.handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="playerName"
                                                className="form-label font-bold"
                                            >
                                                Payer Office Number
                                            </label>
                                            <input
                                                type="text"
                                                id="payerofficenumber"
                                                name="payerofficenumber"
                                                className="form-control"
                                                placeholder="Payer Office Number"
                                                value={formik.values.payerofficenumber}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}


                            <div className="row mt-3">
                                <div className="col-md-4">
                                    <label htmlFor="pat_name_l" className="form-label font-bold">2. PATIENT'S NAME</label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input
                                                type="text"
                                                id="pat_name_l"
                                                name="pat_name_l"
                                                className="form-control"
                                                placeholder="Last Name"
                                            />
                                        </div>
                                        <div className="col-md-5">
                                            <input
                                                type="text"
                                                id="pat_name_f"
                                                name="pat_name_f"
                                                className="form-control"
                                                placeholder="First Name"
                                            />
                                        </div>
                                        <div className="col-md-1">
                                            <input
                                                type="text"
                                                id="pat_name_m"
                                                name="pat_name_m"
                                                className="form-control"
                                                placeholder="M"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="pat_dob" className="form-label font-bold">3. PATIENT'S BIRTHDAY / SEX</label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input
                                                type="date"
                                                id="pat_dob"
                                                name="pat_dob"
                                                className="form-control"
                                                placeholder="DOB"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <select id="pat_sex" name="pat_sex" className="form-select">
                                                <option value="" defaultValue>
                                                    Select Sex
                                                </option>
                                                <option value="M">Male</option>
                                                <option value="F">Female</option>
                                                <option value="U">Unknown</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="ins_name_l" className="form-label font-bold">4. INSURED'S NAME</label>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <input
                                                type="text"
                                                id="ins_name_l"
                                                name="ins_name_l"
                                                className="form-control"
                                                placeholder="Last Name"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <input
                                                type="text"
                                                id="ins_name_f"
                                                name="ins_name_f"
                                                className="form-control"
                                                placeholder="First Name"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <input
                                                type="text"
                                                id="ins_name_m"
                                                name="ins_name_m"
                                                className="form-control"
                                                placeholder="M"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                {/* Patient's Address */}
                                <div className="col-md-4">
                                    <label htmlFor="pat_addr_1" className="form-label font-bold">
                                        5. PATIENT'S ADDRESS
                                    </label>
                                    <input
                                        type="text"
                                        id="pat_addr_1"
                                        name="pat_addr_1"
                                        className="form-control"
                                        placeholder="Enter Patient's Address"
                                    />
                                </div>

                                {/* Patient Relationship */}
                                <div className="col-md-4">
                                    <label htmlFor="pat_rel" className="form-label font-bold">
                                        6. PATIENT RELATIONSHIP
                                    </label>
                                    <select id="pat_rel" name="pat_rel" className="form-select">
                                        <option value="">Choose Relationship</option>
                                        <option value="01">Spouse</option>
                                        <option value="18" selected>
                                            Self
                                        </option>
                                        <option value="19">Child</option>
                                        <option value="20">Employee</option>
                                        <option value="21">Unknown</option>
                                        <option value="39">Organ Donor</option>
                                        <option value="40">Cadaver Donor</option>
                                        <option value="53">Life Partner</option>
                                        <option value="G8">Other Relationship</option>
                                    </select>
                                </div>

                                {/* Insured's Address */}
                                <div className="col-md-4">
                                    <label htmlFor="ins_addr_1" className="form-label font-bold">
                                        7. INSURED'S ADDRESS
                                    </label>
                                    <input
                                        type="text"
                                        id="ins_addr_1"
                                        name="ins_addr_1"
                                        className="form-control"
                                        placeholder="Enter Insured's Address"
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                {/* Patient's Address 2 */}
                                <div className="col-md-4">
                                    <label htmlFor="pat_addr_2" className="form-label font-bold">
                                        ADDRESS 2
                                    </label>
                                    <input
                                        type="text"
                                        id="pat_addr_2"
                                        name="pat_addr_2"
                                        className="form-control"
                                        placeholder="Enter Address 2"
                                    />
                                </div>

                                {/* Marital Status */}
                                <div className="col-md-4">
                                    <label htmlFor="pat_marital" className="form-label font-bold">
                                        8. MARITAL STATUS
                                    </label>
                                    <select id="pat_marital" name="pat_marital" className="form-select">
                                        <option value="" defaultValue>
                                            Select Marital Status
                                        </option>
                                        <option value="S">SINGLE</option>
                                        <option value="M">MARRIED</option>
                                        <option value="O">OTHER</option>
                                    </select>
                                </div>

                                {/* Insured's Address 2 */}
                                <div className="col-md-4">
                                    <label htmlFor="ins_addr_2" className="form-label font-bold">
                                        ADDRESS 2
                                    </label>
                                    <input
                                        type="text"
                                        id="ins_addr_2"
                                        name="ins_addr_2"
                                        className="form-control"
                                        placeholder="Enter Address 2"
                                    />
                                </div>
                            </div>

                            <div className="row mt-3">
                                {/* Patient's Address: City, State, ZIP, Country */}
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <label htmlFor="pat_city" className="form-label font-bold">
                                                CITY
                                            </label>
                                            <input
                                                type="text"
                                                id="pat_city"
                                                name="pat_city"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                value={formik.values.pat_city}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-2">
                                            <label htmlFor="pat_state" className="form-label font-bold">
                                                ST
                                            </label>
                                            <input
                                                type="text"
                                                id="pat_state"
                                                name="pat_state"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                value={formik.values.pat_state}
                                            />
                                        </div>
                                        <div className="col-md-3 mb-2">
                                            <label htmlFor="pat_zip" className="form-label font-bold">
                                                ZIP
                                            </label>
                                            <input
                                                type="text"
                                                id="pat_zip"
                                                name="pat_zip"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                value={formik.values.pat_zip}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="pat_country" className="form-label font-bold">
                                                C
                                            </label>
                                            <input
                                                type="text"
                                                id="pat_country"
                                                name="pat_country"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                value={formik.values.pat_country}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Employment Status */}
                                <div className="col-md-4">
                                    <label htmlFor="pat_employment" className="form-label font-bold">
                                        EMPLOYMENT STATUS
                                    </label>
                                    <select
                                        id="pat_employment"
                                        name="pat_employment"
                                        className="form-select"
                                        onChange={formik.handleChange}
                                        value={formik.values.pat_employment}
                                    >
                                        <option value="">Select Employment Status</option>
                                        <option value="E">Employed</option>
                                        <option value="F">Full-Time Student</option>
                                        <option value="P">Part-Time Student</option>
                                    </select>
                                </div>

                                {/* Insured's Address: City, State, ZIP, Country */}
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-4 mb-2">
                                            <label htmlFor="ins_city" className="form-label font-bold">
                                                CITY
                                            </label>
                                            <input
                                                type="text"
                                                id="ins_city"
                                                name="ins_city"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                value={formik.values.ins_city}
                                            />
                                        </div>
                                        <div className="col-md-2 mb-2">
                                            <label htmlFor="ins_state" className="form-label font-bold">
                                                ST
                                            </label>
                                            <input
                                                type="text"
                                                id="ins_state"
                                                name="ins_state"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                value={formik.values.ins_state}
                                            />
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <label htmlFor="ins_zip" className="form-label font-bold">
                                                ZIP
                                            </label>
                                            <input
                                                type="text"
                                                id="ins_zip"
                                                name="ins_zip"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                value={formik.values.ins_zip}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="ins_country" className="form-label font-bold">
                                                C
                                            </label>
                                            <input
                                                type="text"
                                                id="ins_country"
                                                name="ins_country"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                value={formik.values.ins_country}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">

                                <div className="col-md-4">
                                    <div className="row">
                                        <label className="form-label font-bold">9. Secondary Insured Name (L, F, M)</label>

                                        <div className="col-md-4">
                                            <input
                                                type="text"
                                                name="otherInsNameL"
                                                value={formik.values.otherInsNameL}
                                                onChange={formik.handleChange}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <input
                                                type="text"
                                                name="otherInsNameF"
                                                value={formik.values.otherInsNameF}
                                                onChange={formik.handleChange}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-md-4">

                                            <input
                                                type="text"
                                                name="otherInsNameM"
                                                value={formik.values.otherInsNameM}
                                                onChange={formik.handleChange}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label className="form-label font-bold">a. Secondary Insured Policy </label>
                                        <input
                                            type="text"
                                            name="insuredpolicgroup"
                                            value={formik.values.secondaryinsuredpolicy}
                                            onChange={formik.handleChange}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <div className="row">
                                            <label className="form-label font-bold">a. Secondary Insured Policy DOB/Sex</label>

                                            <div className="col-md-6">
                                                <input
                                                    type="date"
                                                    name="dob"
                                                    value={formik.values.dob}
                                                    onChange={formik.handleChange}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <select
                                                    name="Sex"
                                                    value={formik.values.sex}
                                                    onChange={formik.handleChange}
                                                    className="form-select"
                                                >
                                                    <option value="">Male</option>
                                                    <option value="Y">Female</option>
                                                    <option value="N">Unknown</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <label className="form-label font-bold">10. Patient Condition Related</label>
                                    <div className="row">
                                        <label className="form-label font-bold">a.Employement</label>
                                        <div className="col-md-4">
                                            <select
                                                name="Sex"
                                                value={formik.values.sex}
                                                onChange={formik.handleChange}
                                                className="form-select"
                                            >
                                                <option value="">No</option>
                                                <option value="Y">Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="form-label font-bold">b.Auto Incident</label>

                                                <select
                                                    name="Sex"
                                                    value={formik.values.sex}
                                                    onChange={formik.handleChange}
                                                    className="form-select"
                                                >
                                                    <option value="">No</option>
                                                    <option value="Y">Yes</option>
                                                </select>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label font-bold">State</label>
                                                <input
                                                    type="text"
                                                    name="patientconditionrelatedstate"
                                                    value={formik.values.patientconditionrelatedstate}
                                                    onChange={formik.handleChange}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label font-bold">Country</label>
                                                <input
                                                    type="text"
                                                    name="patientconditionrelatedcountry"
                                                    value={formik.values.patientconditionrelatedcountry}
                                                    onChange={formik.handleChange}
                                                    className="form-control"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="form-label font-bold">c.Other Incident</label>

                                                <select
                                                    name="Sex"
                                                    value={formik.values.otherincident}
                                                    onChange={formik.handleChange}
                                                    className="form-select"
                                                >
                                                    <option value="">No</option>
                                                    <option value="Y">Yes</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <label className="form-label font-bold">10. Insured Policy Group</label>
                                        <input
                                            type="text"
                                            name="insuredpolicgroup"
                                            value={formik.values.insuredpolicgroup}
                                            onChange={formik.handleChange}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <div className="row">
                                            <label className="form-label font-bold">a. Insured's Date Of Birth/Sex</label>

                                            <div className="col-md-6">
                                                <input
                                                    type="date"
                                                    name="insuredpdob"
                                                    value={formik.values.insuredpdob}
                                                    onChange={formik.handleChange}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <select
                                                    name="insuredSex"
                                                    value={formik.values.insuredSex}
                                                    onChange={formik.handleChange}
                                                    className="form-select"
                                                >
                                                    <option value="">Male</option>
                                                    <option value="Y">Female</option>
                                                    <option value="N">Unknown</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div className="row">
                                            <label className="form-label font-bold">b. Employeer's Name</label>
                                            <input
                                                type="text"
                                                name="insuredemployername"
                                                value={formik.values.insuredemployername}
                                                onChange={formik.handleChange}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>


                            <div className="mt-3">
                                <div className="row">
                                    {/* PRIMARY PAYMENT DATE */}
                                    <div className="col-md-4 mb-3">
                                        <label className="form-label font-bold">c. PRIMARY PAYMENT DATE</label>
                                        <input
                                            id="other_ins_payment_date"
                                            name="other_ins_payment_date"
                                            type="date"
                                            value={formik.values.other_ins_payment_date}
                                            onChange={formik.handleChange}
                                            className="form-control"
                                        />
                                    </div>

                                    {/* CLAIM CODES */}
                                    <div className="col-md-4 mb-3">
                                        <label className="form-label font-bold">10d. CLAIM CODES</label>
                                        <input
                                            id="gen_cond_code"
                                            name="gen_cond_code"
                                            type="text"
                                            value={formik.values.gen_cond_code}
                                            onChange={formik.handleChange}
                                            className="form-control"
                                        />
                                    </div>

                                    {/* INSURANCE PLAN NAME */}
                                    <div className="col-md-4 mb-3">
                                        <label className="form-label font-bold">c. INSURANCE PLAN NAME</label>
                                        <input
                                            id="ins_plan"
                                            name="ins_plan"
                                            type="text"
                                            value={formik.values.ins_plan}
                                            onChange={formik.handleChange}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <lablel className="form-label font-bold">SEC PAYER NAME </lablel>
                                            <input

                                                type="text"
                                                name="other_payer_name"
                                                id="other_payer_name"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.other_payer_name}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <lablel className="form-label font-bold">PAYER ID </lablel>

                                            <input
                                                type="text"
                                                name="other_payerid"
                                                id="other_payerid"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.other_payerid}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-8">
                                            <lablel className="form-label font-bold">SECONDARY PAYER CLAIM ID </lablel>

                                            <input
                                                type="text"
                                                name="icn_dcn_2"
                                                id="icn_dcn_2"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.icn_dcn_2}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="show_other_payaddr_check" />
                                                <label className="form-label font-bold" for="show_other_payaddr_check">
                                                    Show Addres
                                                </label>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <label className="form-label font-bold"> Medicare Type Code:</label>
                                            <select
                                                name="other_ins_medicare_code"
                                                className="form-select"
                                                id="other_ins_medicare_code"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.other_ins_medicare_code}

                                            >
                                                <option value=""></option>
                                                <option value="12">12 Working Age</option>
                                                <option value="43">43 Medicare Disabled</option>
                                                <option value="11">11 Conditional / Accident</option>
                                                <option value="13">13 End-Stage Renal Beneficiary</option>
                                                <option value="14">14 Auto accident</option>
                                                <option value="15">15 Workers Comp / Black lung</option>
                                                <option value="16">16 Other Federal Agency</option>
                                                <option value="19">19 WCMSA</option>
                                                <option value="41">41 Black lung</option>
                                                <option value="42">42 Veteran's Administration</option>
                                                <option value="47">47 Liability</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div style={{ display: 'none' }} id="filing_code">
                                        <div>
                                            <span id="other_payer_tag" className="otherpayer1title">SECONDARY</span> PAYER FILING CODE<br />
                                            <select
                                                name="other_claimfilingcode"
                                                className="inp"
                                                id="other_claimfilingcode"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.other_claimfilingcode}
                                                style={{ width: '98%' }}
                                            >
                                                <option value=""></option>
                                                <option value="11">Other Non-Federal Programs</option>
                                                <option value="12">Preferred Provider Organization (PPO)</option>
                                                <option value="13">Point of Service (POS)</option>
                                                <option value="14">Exclusive Provider Organization (EPO)</option>
                                                <option value="15">Indemnity Insurance</option>
                                                <option value="16">Health Maintenance Organization (HMO) Medicare Risk</option>
                                                <option value="17">Dental Maintenance Organization</option>
                                                <option value="AM">Automobile Medical</option>
                                                <option value="BL">Blue Cross/Blue Shield</option>
                                                <option value="CH">Champus</option>
                                                <option value="CI">Commercial Insurance Co.</option>
                                                <option value="DS">Disability</option>
                                                <option value="FI">Federal Employees Program</option>
                                                <option value="HM">Health Maintenance Organization</option>
                                                <option value="LM">Liability Medical</option>
                                                <option value="MA">Medicare Part A</option>
                                                <option value="MB">Medicare Part B</option>
                                                <option value="MC">Medicaid</option>
                                                <option value="OF">Other Federal Program</option>
                                                <option value="TV">Title V</option>
                                                <option value="VA">Veteran Administration Plan</option>
                                                <option value="WC">Workers' Compensation Health Claim</option>
                                                <option value="ZZ">Mutually Defined</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <label className="form-label font-bold">9e. SECOMDARY PATIENT RELATIONSHIP</label>
                                        <select
                                            name="other_pat_rel"
                                            id="other_pat_rel"
                                            className="form-select"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.other_pat_rel}
                                        >
                                            <option value=""></option>
                                            <option value="18">18 Self</option>
                                            <option value="19">19 Spouse</option>
                                            <option value="20">20 Child</option>
                                            <option value="21">21 Employee</option>
                                            <option value="22">22 Other</option>
                                        </select>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <label className="form-label font-bold">9f. SECONDARY PAYER GROUP NAME</label>
                                            <input
                                                type="text"
                                                name="secondary_payer_group_name_9f"
                                                id="secondary_payer_group_name_9f"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                checked={formik.values.other_ins_plan}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <label className="form-label font-bold">9g. SECONDARY PAYER GROUP NAME</label>
                                        <input
                                            type="text"
                                            name="secondary_payer_group_name_9g"
                                            id="secondary_payer_group_name_9g"
                                            className="form-control"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            checked={formik.values.other_ins_plan}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <label className="form-label font-bold">SUPERVISIOR PROVIDER NAME(L,F,M)</label>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <input
                                                type="text"
                                                name="supv_prov_name_l"
                                                id="supv_prov_name_l"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.supv_prov_name_l}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <input
                                                type="text"
                                                name="supv_prov_name_f"
                                                id="supv_prov_name_f"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.supv_prov_name_f}
                                            />

                                        </div>
                                        <div className="col-md-4">
                                            <input
                                                type="text"
                                                name="supv_prov_name_m"
                                                id="supv_prov_name_m"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.supv_prov_name_m}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-6 row">
                                            <label className="form-label font-bold">Id</label>
                                            <input
                                                type="text"
                                                name="Id"
                                                value={formik.values.insuredemployername}
                                                onChange={formik.handleChange}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-md-6 row">
                                            <label className="form-label font-bold">NPI</label>
                                            <input
                                                type="text"
                                                name="NPI"
                                                value={formik.values.insuredemployername}
                                                onChange={formik.handleChange}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div id="other_payer_addr_block" style={{ display: 'none' }}>
                                    <input type="hidden" name="other_payer_phone" value="" />
                                    <div>Address 1
                                        <input
                                            style={{ width: '98%' }}
                                            type="text"
                                            name="other_payer_addr_1"
                                            id="other_payer_addr_1"
                                            className="inp"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.other_payer_addr_1}
                                        />
                                    </div>
                                    <div>Address 2
                                        <input
                                            style={{ width: '98%' }}
                                            type="text"
                                            name="other_payer_addr_2"
                                            id="other_payer_addr_2"
                                            className="inp"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.other_payer_addr_2}
                                        />
                                    </div>
                                    <div style={{ width: '97%' }}>
                                        <div className="txt" style={{ width: '60%', paddingRight: '2px' }}>
                                            City<br />
                                            <input
                                                style={{ width: '98%' }}
                                                type="text"
                                                name="other_payer_city"
                                                id="other_payer_city"
                                                className="inp"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.other_payer_city}
                                            />
                                        </div>
                                        <div className="txt" style={{ width: '10%', paddingRight: '2px' }}>
                                            St<br />
                                            <input
                                                style={{ width: '98%' }}
                                                type="text"
                                                name="other_payer_state"
                                                id="other_payer_state"
                                                className="inp"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.other_payer_state}
                                            />
                                        </div>
                                        <div className="txt" style={{ width: '30%' }}>
                                            Zip<br />
                                            <input
                                                style={{ width: '98%' }}
                                                type="text"
                                                name="other_payer_zip"
                                                id="other_payer_zip"
                                                className="inp"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.other_payer_zip}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-4">
                                        <div className="row">
                                            <label className="form-label font-bold">14. DATE OF CONDITION</label>
                                            <input
                                                type="text"
                                                name="date_of_condition"
                                                id="date_of_condition"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.date_of_condition}
                                            />
                                        </div>
                                        <div className="row">
                                            <label className="form-label font-bold">ADD DATE</label>
                                            <select name="date_selector" class="form-select" id="date_selector" >
                                                <option selected="selected" value="">Add Date Field</option>
                                                <option value="lastseen_date">Date Last Seen (304)</option>
                                                <option value="chiro_manifest_date">Acute Manifest Date (453)</option>
                                                <option value="accident_date">Accident Date (439)</option>
                                                <option value="initial_treatment_date">Initial Treatment Date (454)</option>
                                                <option value="last_menstrual_date">Last Menstrual Period (484)</option>
                                                <option value="chiro_xray_date">Last X-Ray Date (455)</option>
                                                <option value="assumed_care_date">Assumed Care Date (090)</option>
                                                <option value="relinquished_care_date">Relinquished Care Date (091)</option>
                                                <option value="lastworked_date">Last Worked Date (297)</option>
                                                <option value="disability_from_date">Disability From Date (360)</option>
                                                <option value="disability_thru_date">Disability Thru Date (361)</option>
                                                <option value="hearingvision_prescription_date">Hearing and Vision Prescription Date (471)</option>
                                                <option value="lastworked_date">Last Worked Date (297)</option>
                                                <option value="returntowork_date">Authorized Return To Work Date (296)</option>
                                                <option value="firstcontact_date">First Contact Date (444)</option>
                                                <option value="pat_death_date">Patient Death Date</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label font-bold">REFERING PHYSICIAN NAME</label>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <input
                                                    type="text"
                                                    name="refering_physican_name_L"
                                                    id="refering_physican_name_L"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.refering_physican_name_L}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <input
                                                    type="text"
                                                    name="refering_physican_name_F"
                                                    id="refering_physican_name_F"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.refering_physican_name_F}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <input
                                                    type="text"
                                                    name="refering_physican_name_M"
                                                    id="refering_physican_name_M"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.refering_physican_name_M}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="form-label font-bold">17. REFERING NPI</label>
                                                <input
                                                    type="text"
                                                    name="refering_NPI"
                                                    id="refering_NPI"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.refering_NPI}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label font-bold">17. Other ID</label>
                                                <input
                                                    type="text"
                                                    name="other_Id"
                                                    id="other_Id"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.other_Id}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="row mt-3">
                                    <div className="col-md-8">
                                        <label className="form-label font-bold">19. CLAIM NARRATIVE</label>
                                        <input
                                            type="text"
                                            name="claim_narative"
                                            id="claim_narative"
                                            className="form-control"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.claim_narative}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label font-bold">18. HOSPITALIZATION DATE</label>
                                        <div className="row align-items-center">
                                            <div className="col-md-6 d-flex align-items-center">
                                                <label htmlFor="to_hospitalizationdate" className="me-2">TO</label>
                                                <input
                                                    type="date"
                                                    name="to_hospitalizationdate"
                                                    id="to_hospitalizationdate"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.to_hospitalizationdate}
                                                />
                                            </div>
                                            <div className="col-md-6 d-flex align-items-center">
                                                <label htmlFor="from_hospitalizationdate" className="me-2">FROM</label>
                                                <input
                                                    type="date"
                                                    name="from_hospitalizationdate"
                                                    id="from_hospitalizationdate"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.from_hospitalizationdate}
                                                />
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-8">
                                        <label className="form-label font-bold">21. DIAGNOSIS OR NATURE OF ILLNESS OR INJURY (Relate to 24E by line)</label>
                                        <div className="row align-items-center">
                                            <div className="col-md-3 ">
                                                {/* Diagnose A */}
                                                <div className="d-flex align-items-center mb-3">
                                                    <label htmlFor="diagnose_A" className="me-2">A.</label>
                                                    <input
                                                        type="text"
                                                        name="diagnose_A"
                                                        id="diagnose_A"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.diagnose_A}
                                                    />
                                                </div>

                                                {/* Diagnose E */}
                                                <div className="d-flex align-items-center mb-3">
                                                    <label htmlFor="diagnose_E" className="me-2">E.</label>
                                                    <input
                                                        type="text"
                                                        name="diagnose_E"
                                                        id="diagnose_E"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.diagnose_E}
                                                    />
                                                </div>

                                                {/* Diagnose I */}
                                                <div className="d-flex align-items-center mb-3">
                                                    <label htmlFor="diagnose_I" className="me-2">I.</label>
                                                    <input
                                                        type="text"
                                                        name="diagnose_I"
                                                        id="diagnose_I"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.diagnose_I}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-3 ">
                                                {/* Diagnose A */}
                                                <div className="d-flex align-items-center mb-3">
                                                    <label htmlFor="diagnose_A" className="me-2">B.</label>
                                                    <input
                                                        type="text"
                                                        name="diagnose_B"
                                                        id="diagnose_B"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.diagnose_B}
                                                    />
                                                </div>

                                                {/* Diagnose E */}
                                                <div className="d-flex align-items-center mb-3">
                                                    <label htmlFor="diagnose_E" className="me-2">F.</label>
                                                    <input
                                                        type="text"
                                                        name="diagnose_F"
                                                        id="diagnose_F"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.diagnose_F}
                                                    />
                                                </div>

                                                {/* Diagnose I */}
                                                <div className="d-flex align-items-center mb-3">
                                                    <label htmlFor="diagnose_I" className="me-2">J.</label>
                                                    <input
                                                        type="text"
                                                        name="diagnose_J"
                                                        id="diagnose_J"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.diagnose_J}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 ">
                                                {/* Diagnose A */}
                                                <div className="d-flex align-items-center mb-3">
                                                    <label htmlFor="diagnose_A" className="me-2">C.</label>
                                                    <input
                                                        type="text"
                                                        name="diagnose_C"
                                                        id="diagnose_C"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.diagnose_C}
                                                    />
                                                </div>

                                                {/* Diagnose E */}
                                                <div className="d-flex align-items-center mb-3">
                                                    <label htmlFor="diagnose_E" className="me-2">G.</label>
                                                    <input
                                                        type="text"
                                                        name="diagnose_G"
                                                        id="diagnose_G"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.diagnose_G}
                                                    />
                                                </div>

                                                {/* Diagnose I */}
                                                <div className="d-flex align-items-center mb-3">
                                                    <label htmlFor="diagnose_I" className="me-2">K.</label>
                                                    <input
                                                        type="text"
                                                        name="diagnose_K"
                                                        id="diagnose_K"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.diagnose_I}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 ">
                                                {/* Diagnose A */}
                                                <div className="d-flex align-items-center mb-3">
                                                    <label htmlFor="diagnose_A" className="me-2">D.</label>
                                                    <input
                                                        type="text"
                                                        name="diagnose_D"
                                                        id="diagnose_D"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.diagnose_D}
                                                    />
                                                </div>

                                                {/* Diagnose E */}
                                                <div className="d-flex align-items-center mb-3">
                                                    <label htmlFor="diagnose_E" className="me-2">H.</label>
                                                    <input
                                                        type="text"
                                                        name="diagnose_H"
                                                        id="diagnose_H"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.diagnose_H}
                                                    />
                                                </div>

                                                {/* Diagnose I */}
                                                <div className="d-flex align-items-center mb-3">
                                                    <label htmlFor="diagnose_I" className="me-2">L.</label>
                                                    <input
                                                        type="text"
                                                        name="diagnose_L"
                                                        id="diagnose_L"
                                                        className="form-control"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.diagnose_L}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row align-items-center mb-3">
                                            {/* Label */}
                                            <label htmlFor="playertype" className="form-label mb-0 me-2 font-bold">
                                                22. RESUBMISSION CODE (PAYER ICN)
                                            </label>

                                            {/* Dropdown */}
                                            <div className="col-md-4">
                                                <select
                                                    className="form-select"
                                                    id="resubmission_code"
                                                    value={formik.values.resubmission_code}
                                                    onChange={formik.handleChange}
                                                >
                                                    <option value="">AUTOMATIC CORRECT</option>
                                                    <option value="1">1 ORIGINAL CLAIM</option>
                                                    <option value="7">7 CORRECTED CLAIM</option>
                                                    <option value="8">8 VOID CLAIM</option>
                                                </select>
                                            </div>

                                            {/* Textbox */}
                                            <div className="col-md-8">
                                                <input
                                                    type="text"
                                                    name="payer_icn"
                                                    id="payer_icn"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.payer_icn}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="form-label font-bold">23. PRIOR AUTHORIZATION NUMBER</label>
                                            <input
                                                type="text"
                                                name="prior_authorization_number"
                                                id="prior_authorization_number"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.prior_authorization_number}
                                            />
                                        </div>
                                        <div className="row">
                                            <label className="form-label font-bold">REFERRAL NUMBER</label>
                                            <input
                                                type="text"
                                                name="referal_number"
                                                id="referal_number"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.referal_number}
                                            />
                                        </div>
                                        <div className="row">
                                            <label className="form-label font-bold">CLIA NUMBER</label>
                                            <input
                                                type="text"
                                                name="clia_number"
                                                id="clia_number"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.clia_number}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>24. A. DATE(S) OF SERVICE</th>
                                                <th>B. POS</th>
                                                <th>C. EMG</th>
                                                <th>D. PROCEDURE</th>
                                                <th>MODIFIER</th>
                                                <th>E. DIAG REF</th>
                                                <th>F. $ CHARGES</th>
                                                <th>G. UNITS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows.map((row, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="d-flex">
                                                            <input
                                                                type="date"
                                                                className="form-control me-2"
                                                                value={row.serviceDateStart}
                                                                onChange={(e) =>
                                                                    handleRowChange(index, "serviceDateStart", e.target.value)
                                                                }
                                                            />
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                value={row.serviceDateEnd}
                                                                onChange={(e) =>
                                                                    handleRowChange(index, "serviceDateEnd", e.target.value)
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={row.pos}
                                                            onChange={(e) =>
                                                                handleRowChange(index, "pos", e.target.value)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <select
                                                            className="form-select"
                                                            value={row.emg}
                                                            onChange={(e) =>
                                                                handleRowChange(index, "emg", e.target.value)
                                                            }
                                                        >
                                                            <option value=""> </option>
                                                            <option value="YES">YES</option>
                                                            <option value="NO">NO</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={row.procedure}
                                                            onChange={(e) =>
                                                                handleRowChange(index, "procedure", e.target.value)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="d-flex">
                                                            {row.modifiers.map((mod, modIndex) => (
                                                                <input
                                                                    key={modIndex}
                                                                    type="text"
                                                                    className="form-control me-1"
                                                                    value={mod}
                                                                    onChange={(e) =>
                                                                        handleModifierChange(index, modIndex, e.target.value)
                                                                    }
                                                                />
                                                            ))}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={row.diagRef}
                                                            onChange={(e) =>
                                                                handleRowChange(index, "diagRef", e.target.value)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={row.charges}
                                                            onChange={(e) =>
                                                                handleRowChange(index, "charges", e.target.value)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={row.units}
                                                            onChange={(e) =>
                                                                handleRowChange(index, "units", e.target.value)
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                </div>
                                <div className="row mt-4">
                                    <div className="col text-end">
                                        <Button variant="primary" onClick={addNewRow}>
                                            Add Row
                                        </Button>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-2">
                                        <label className="form-label font-bold">25. TAX ID</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input
                                                    type="text"
                                                    name="tax_id"
                                                    id="tax_id"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.tax_id}
                                                />

                                            </div>
                                            <div className="col-md-6">
                                                <select
                                                    className="form-select "
                                                    id="playertype"
                                                    value={formik.values.playertype}

                                                >
                                                    <option value=""> </option>
                                                    <option value="EIN">EIN</option>
                                                    <option value="SSN">SSN</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label font-bold">26. PATIENT ACCT #</label>
                                        <input
                                            type="text"
                                            name="patient_acc"
                                            id="patient_acc"
                                            className="form-control"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.patient_acc}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label font-bold">27. ACCEPT ASSIGN</label>
                                        <select
                                            className="form-select "
                                            id="playertype"
                                            value={formik.values.playertype}

                                        >
                                            <option value=""> </option>
                                            <option value="YES">YES</option>
                                            <option value="YESLABONLY">YES LAB ONLY</option>
                                            <option value="NO">NO</option>

                                        </select>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label font-bold">28. TOTAL CHARGE</label>
                                        <input
                                            type="text"
                                            name="total_charge"
                                            id="total_charge"
                                            className="form-control"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.total_charge}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label font-bold">29. AMOUNT PAID</label>
                                        <input
                                            type="text"
                                            name="amount_paid"
                                            id="amount_paid"
                                            className="form-control"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.amount_paid}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label font-bold">30. BALANCE</label>
                                        <input
                                            type="text"
                                            name="balance"
                                            id="balance"
                                            className="form-control"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.balance}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-4">
                                        <label className="form-label font-bold">31. RENDERING PROVIDER (Last, First, Middle)</label>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <input
                                                    type="text"
                                                    name="renerding_provider_L"
                                                    id="renerding_provider_L"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.renerding_provider_L}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <input
                                                    type="text"
                                                    name="renerding_provider_F"
                                                    id="renerding_provider_F"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.renerding_provider_F}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <input
                                                    type="text"
                                                    name="renerding_provider_M"
                                                    id="renerding_provider_M"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.renerding_provider_M}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label className="form-label font-bold">32. FACILITY</label>
                                                <input
                                                    type="text"
                                                    name="facility"
                                                    id="facility"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.facility}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label className="form-label font-bold">Address 1</label>

                                                <input
                                                    type="text"
                                                    name="facility_address_1"
                                                    id="facility_address_1"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.facility_address_1}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label className="form-label font-bold">Address 2</label>

                                                <input
                                                    type="text"
                                                    name="facility_address_2"
                                                    id="facility_address_2"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.facility_address_2}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <label className="form-label font-bold">City</label>

                                                <input
                                                    type="text"
                                                    name="facility_City"
                                                    id="facility_City"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.facility_City}
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <label className="form-label font-bold">ST</label>

                                                <input
                                                    type="text"
                                                    name="facility_ST"
                                                    id="facility_ST"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.facility_ST}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label font-bold">ZIP</label>

                                                <input
                                                    type="text"
                                                    name="facility_ZIP"
                                                    id="facility_ZIP"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.facility_ZIP}
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <label className="form-label font-bold">C</label>

                                                <input
                                                    type="text"
                                                    name="facility_C"
                                                    id="facility_C"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.facility_C}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <label className="form-label font-bold">33. BILLING PROVIDER</label>

                                            <input
                                                type="text"
                                                name="biling_provider"
                                                id="biling_provider"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.biling_provider}
                                            />
                                        </div>
                                        <div className="row">
                                            <label className="form-label font-bold">Address 1</label>

                                            <input
                                                type="text"
                                                name="biling_provider_address_1"
                                                id="biling_provider_address_1"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.biling_provider_address_1}
                                            />
                                        </div>
                                        <div className="row">
                                            <label className="form-label font-bold">Address 2</label>

                                            <input
                                                type="text"
                                                name="biling_provider_address_2"
                                                id="biling_provider_address_2"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.biling_provider_address_2}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="form-label font-bold">CITY</label>

                                                <input
                                                    type="text"
                                                    name="biling_provider_city"
                                                    id="biling_provider_city"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.biling_provider_city}
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <label className="form-label font-bold">ST</label>

                                                <input
                                                    type="text"
                                                    name="biling_provider_ST"
                                                    id="biling_provider_ST"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.biling_provider_ST}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label font-bold">ZIP</label>

                                                <input
                                                    type="text"
                                                    name="biling_provider_zip"
                                                    id="biling_provider_zip"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.biling_provider_zip}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="form-label font-bold">Phone</label>

                                            <input
                                                type="text"
                                                name="biling_provider_phone"
                                                id="biling_provider_phone"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.biling_provider_phone}
                                            />
                                        </div>
                                        <div className="row">
                                            <label className="form-label font-bold">Taxonomy</label>

                                            <input
                                                type="text"
                                                name="biling_provider_taxonomy"
                                                id="biling_provider_taxonomy"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.biling_provider_taxonomy}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="form-label font-bold">NPI :</label>
                                                <input
                                                    type="text"
                                                    name="npi"
                                                    id="npi"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.npi}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label font-bold">PROV ID: :</label>
                                                <input
                                                    type="text"
                                                    name="prov_id"
                                                    id="prov_id"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.npi}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="form-label font-bold">NPI :</label>
                                                <input
                                                    type="text"
                                                    name="npi"
                                                    id="npi"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.npi}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label font-bold">FACIL ID: :</label>
                                                <input
                                                    type="text"
                                                    name="facil_id"
                                                    id="facil_id"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.facil_id}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="form-label font-bold">NPI :</label>
                                                <input
                                                    type="text"
                                                    name="npi"
                                                    id="npi"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.npi}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label font-bold">BILL ID:</label>
                                                <input
                                                    type="text"
                                                    name="bill_id"
                                                    id="bill_id"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.bill_id}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="form-label font-bold">PAY-TO Address</label>
                                            <input
                                                type="text"
                                                name="pay_to_address"
                                                id="pay_to_address"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.pay_to_address}
                                            />
                                        </div>
                                        <div className="row">
                                            <label className="form-label font-bold">Address 2</label>
                                            <input
                                                type="text"
                                                name="pay_to_address_2"
                                                id="pay_to_address_2"
                                                className="form-control"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.pay_to_address_2}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="form-label font-bold">CITY</label>
                                                <input
                                                    type="text"
                                                    name="pay_to_address_city"
                                                    id="pay_to_address_city"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.pay_to_address_city}
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <label className="form-label font-bold">ST</label>
                                                <input
                                                    type="text"
                                                    name="pay_to_address_st"
                                                    id="pay_to_address_st"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.pay_to_address_st}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label font-bold">ZIP</label>
                                                <input
                                                    type="text"
                                                    name="pay_to_address_zip"
                                                    id="pay_to_address_zip"
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.pay_to_address_zip}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <button type="submit" className="btn btn-primary float-end">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageClaim;
