import React, { useState } from "react";
import { IoPersonSharp } from "react-icons/io5";
import { FaLock } from "react-icons/fa6";
import { SignInSchema } from "../../schemas/index";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setAuth } from "../../Redux-store/authSlice";
import { showToast } from "../../services/toastifyservices";
import "react-toastify/dist/ReactToastify.css";
import { postaxiosRequest } from "../../api/api";
import CommonSpinner from "../../services/commonSpinner";
import { links } from "../../data/dummy";
import "./signIn.css";

const initialValues = {
  email: "",
  password: "",
  otp: "",
};
const SignIn = ({}) => {
  const navigate = useNavigate();
  const [showOTP, setShowOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: SignInSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      try {
        const payloadObject = {
          email: values.email,
          password: values.password,
        };
        const response = await postaxiosRequest("login", payloadObject);
        if (response.success) {
          //debugger;
          setLoading(false);
          var result = response.data;
          showToast("success", result.message);
          localStorage.setItem("user", JSON.stringify(result.user));
          localStorage.setItem("token", result.token);
          dispatch(
            setAuth({
              userId: result.user.id,
              role: result.user.role,
              token: result.token,
              isLoggedIn: true,
            })
          );
          navigate("/dashboard");
          // if (result.status) {

          //     setShowOTP(true);
          // } else if (data.status == false) {
          //     showToast('error', data.message);

          // }
          // else {
          //     showToast('error', data.message);
          // }
        }
      } catch (error) {
        //debugger;
        showToast("error", error.response.data.message);
        setLoading(false);
      }
    },
  });

  const handleOTPSubmit = async () => {
    if (values.otp == "") {
      showToast("error", "Please Enter OTP");
      return;
    }
    setLoading(true);
    const otpPayload = {
      email: values.email,
      otp_code: values.otp,
    };
    try {
      const response = await postaxiosRequest("verify-otp", otpPayload);
      setLoading(false);
      if (response.success) {
        setLoading(false);
        var result = response.data;
        localStorage.setItem("user", JSON.stringify(result.user));
        localStorage.setItem("token", result.token);
        dispatch(
          setAuth({
            userId: result.user.id,
            role: result.user.role,
            token: result.token,
            isLoggedIn: true,
          })
        );
        navigate("/dashboard");
      } else {
        showToast("error", result.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    <>
      {/* {loading && <CommonSpinner loading={loading} />} */}

      <div className="row g-4 mt-5 ">
        <div className="container">
          <div className="card">
            <div className="card-body cardColor">
              <p className="heading text-center">Sign in to your account</p>
              <form
                onSubmit={
                  !showOTP
                    ? handleSubmit
                    : (e) => {
                        e.preventDefault();
                        handleOTPSubmit();
                      }
                }
              >
                <input type="hidden" style={{ display: "none" }} />
                <div className="d-flex justify-content-center">
                  <div className="col-12 col-md-8">
                    <div className="position-relative">
                      <span className="iconInsideTextbox">
                        <IoPersonSharp />
                      </span>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control textbox-with-icon"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.email && touched.email ? (
                      <p className="form-error">{errors.email}</p>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-3">
                  <div className="col-12 col-md-8">
                    <div className="position-relative">
                      <span className="iconInsideTextbox">
                        <FaLock />
                      </span>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        className="form-control textbox-with-icon"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.password && touched.password ? (
                      <p className="form-error">{errors.password}</p>
                    ) : null}
                  </div>
                </div>

                <div className="mt-4 d-flex justify-content-center">
                  <div className="col-12 col-md-8">
                    <button
                      type="submit"
                      className="btnColor form-control"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm me-2 btn-spinner"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Signing in...
                        </>
                      ) : (
                        "Sign In"
                      )}
                    </button>
                  </div>
                </div>
                {/* Show email and password fields if OTP is not shown */}
                {/* {!showOTP ? (
                                    <>
                                        <div className="d-flex justify-content-center">
                                            <div className="col-12 col-md-8">
                                                <div className="position-relative">
                                                    <span className="iconInsideTextbox">
                                                        <IoPersonSharp />
                                                    </span>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        name="email"
                                                        className="form-control textbox-with-icon"
                                                        placeholder="Email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                                {errors.email && touched.email ? <p className='form-error'>{errors.email}</p> : null}
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center mt-3">
                                            <div className="col-12 col-md-8">
                                                <div className="position-relative">
                                                    <span className="iconInsideTextbox">
                                                        <FaLock />
                                                    </span>
                                                    <input
                                                        type="password"
                                                        id="password"
                                                        name="password"
                                                        className="form-control textbox-with-icon"
                                                        placeholder="Password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                                {errors.password && touched.password ? <p className='form-error'>{errors.password}</p> : null}
                                            </div>
                                        </div>

                                        <div className="mt-4 d-flex justify-content-center">
                                            <div className="col-12 col-md-8">
                                                <button type="submit" className="btnColor form-control" disabled={loading}>
                                                    {loading ? (
                                                        <>
                                                            <span
                                                                className="spinner-border spinner-border-sm me-2 btn-spinner"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                            Signing in...
                                                        </>
                                                    ) : (
                                                        "Sign In"
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    // Show OTP input if showOTP is true
                                    <div className="d-flex justify-content-center">
                                        <div className="col-12 col-md-8">
                                            <div className="position-relative">
                                                <input
                                                    type="text"
                                                    id="otp"
                                                    name="otp"
                                                    className="form-control OTPtextBox"
                                                    placeholder="Enter OTP"
                                                    value={values.otp}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            {errors.otp && touched.otp ? <p className='form-error'>{errors.otp}</p> : null}

                                            <div className="mt-4 d-flex justify-content-center">
                                                <div className="col-12 col-md-8">
                                                    <button type="submit" className="btnColor form-control" disabled={loading}>
                                                        {loading ? (
                                                            <>
                                                                <span
                                                                    className="spinner-border spinner-border-sm me-2"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                ></span>
                                                                Verifying OTP...
                                                            </>
                                                        ) : (
                                                            "Verify"
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )} */}
              </form>
            </div>
          </div>
        </div>

        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </>
  );
};
export default SignIn;
