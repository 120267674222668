import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import {
  postaxiosRequest,
  getaxiosRequestWithToken,
  postaxiosRequestWithToken,
} from "../../api/api";
import { showToast } from "../../services/toastifyservices";
import { handleSession } from "../../api/apiSessionHandle";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../../services/commonSpinner";
import "./schedule.css";
import Pusher from "pusher-js";

const Schedule = () => {
  const navigate = useNavigate();
  const pusherKey = "f5d7f359a1682ae86bd6";
  const [providers, setProviders] = useState([]);
  const [dictations, setDictations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [provider_id, setProviderId] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointments, setSelectedAppointments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [btnloading, setbtnLoading] = useState(false);
  const [selectedSummaryModal, setSelectedSummaryModal] = useState(false);
  const [selectedAppt, setSelectedAppt] = useState([]);
  const [refresh, setRefresh] = useState(false);

  //useEffect(() => {
  appointments.forEach((appointment) => {
    // console.log("appointment:", appointment);
    const { appointment_id, call_response } = appointment;
    if (call_response.call_status === "completed") {
      const listen_url = call_response?.listen_url;
      console.log("listen_url:", listen_url);
    }
    //console.log("appointment_id:", appointment_id);
  });
  // }, [appointments]);

  useEffect(() => {
    fetchProviders(navigate, setProviders, setError, setLoading);
  }, [navigate]);

  useEffect(() => {
    // Set the default value to today's date in the format YYYY-MM-DD
    const today = new Date().toISOString().split("T")[0];
    setFromDate(today);
    setToDate(today);
  }, []);

  //!<-----------------Pusher Implementation------------->
  useEffect(() => {
    const pusher = new Pusher(pusherKey, {
      cluster: "mt1",
    });
    const callChannel = pusher.subscribe("outbound-channel");
    callChannel.bind("outbound-event", function (data) {
      //console.log("data of pusher", data);
      setRefresh(true);
    });

    return () => {
      callChannel.unbind("outbound-event");
      pusher.unsubscribe("outbound-channel");
    };
  }, []);

  const fetchProviders = async (
    navigate,
    setProviders,
    setError,
    setLoading
  ) => {
    try {
      const response = await getaxiosRequestWithToken("get-providers");
      if (response.success) {
        //debugger;
        setProviders(response.data.providers || []);
      }
    } catch (error) {
      showToast("error", error.message);
      handleSession(error.response?.data, navigate);
    } finally {
      setLoading(false);
    }
  };

  //--------------------------on Search Button---------------------//
  const handleSearch = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    const Payload = {
      provider_id: provider_id,
      start_date: fromDate,
      end_date: toDate,
    };
    try {
      const response = await postaxiosRequestWithToken(
        "fetch-pending-appts-for-confirmation-call",
        Payload
      );
      if (response.success) {
        setLoading(false);
        if (response.data.data.length > 0) {
          setSelectedAppointments([]);
          setAppointments(response.data.data || []);
        } else {
          showToast("error", "No Record Found");
        }
      }
    } catch (error) {
      showToast("error", error.message);
      setLoading(false);
      handleSession(error.response?.data, navigate);
      // handleSession(error.response.data, navigate);
    } finally {
      setLoading(false);
    }
  };
  //!<-----------------fetch data after receiving push notification------------->
  useEffect(() => {
    if (refresh) {
      handleSearch();
      setRefresh(false);
    }
  }, [refresh]);

  const handleCheckboxChange = (appointmentId) => {
    setSelectedAppointments((prevSelectedAppointments) => {
      if (prevSelectedAppointments.includes(appointmentId)) {
        // Deselect the checkbox
        return prevSelectedAppointments.filter((id) => id !== appointmentId);
      } else {
        // Select the checkbox
        return [...prevSelectedAppointments, appointmentId];
      }
    });
  };
  //<-----------------End Task Section------------->
  // Handle Modal Toggle
  const handleModalToggle = () => setShowModal((prev) => !prev);

  // Get details of selected rows
  const selectedRows = appointments.filter((appointment) =>
    selectedAppointments.includes(appointment.appointment_id)
  );

  //!<----------------select all Section------------->
  const handleSelectAll = () => {
    if (selectedAppointments.length === appointments.length) {
      // Deselect all
      setSelectedAppointments([]);
    } else {
      // Select all
      const allAppointmentIds = appointments.map(
        (appointment) => appointment.appointment_id
      );
      setSelectedAppointments(allAppointmentIds);
    }
  };

  const summaryModal = (appointment) => {
    setSelectedSummaryModal(true);
    setSelectedAppt(appointment);
  };

  const today = new Date().toISOString().split("T")[0];

  const handleCreateAppointment = async () => {
    try {
      setbtnLoading(true);
      setLoading(true);
      const Payload = selectedRows.map((appointment) => ({
        phone: appointment.patient?.phone, // Static phone number
        variable_values: {
          name: `${appointment.patient?.first_name || ""} ${
            appointment.patient?.last_name || ""
          }`,
          provider_name: appointment.provider || "",
          start_time: `${appointment.date}T${appointment.time}-05:00`,
          address: appointment.location?.address || "",
          appointment_id: appointment.appointment_id,
          visit_reason: appointment.visit_reason || "",
        },
      }));
      const formattedPayload = JSON.stringify(Payload);

      const response = await postaxiosRequestWithToken(
        "call-to-confirm-appt",
        // "create-call",
        formattedPayload
      );
      if (response.success) {
        console.log("response during calling", response);
        showToast("success", response.data.message);
      }
    } catch (error) {
      showToast("error", error.message);
      setLoading(false);
      setbtnLoading(false);
      handleSession(error.response?.data, navigate);
      handleSession(error.response.data, navigate);
    } finally {
      handleSearch();
      setSelectedAppointments([]);
      setAppointments([]);
      setLoading(false);
      setbtnLoading(false);
      setShowModal(false);
    }
  };

  //! text formmating ...
  function formatText(input) {
    if (input) {
      return input
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
  }
  return (
    <div className="mt-3 p-2 md:p-10 bg-gray-200">
      <div className="card">
        <span className="text-start font-bold ml-4 p-2 headingtext">
          Scheduling
        </span>
      </div>
      <div className="card mt-3">
        <form onSubmit={handleSearch}>
          <div className="card-body">
            <div className="row">
              <div className="col">
                <label
                  htmlFor="providerselect"
                  className="form-label font-bold"
                  style={{ minWidth: "100px" }}
                >
                  Provider <span className="text-red">*</span>
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  id="providerselect"
                  value={provider_id}
                  onChange={(e) => setProviderId(e.target.value)}
                  required
                >
                  <option value="">Select Provider Name</option>
                  {providers.map((provider) => (
                    <option key={provider.azz_id} value={provider.azz_id}>
                      {provider.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col">
                <label
                  htmlFor="datePicker"
                  className="form-label font-bold"
                  style={{ minWidth: "100px" }}
                >
                  From Date <span className="text-red">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="datePicker"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  min={today}
                  required
                />
              </div>

              <div className="col">
                <label
                  htmlFor="datePicker"
                  className="form-label font-bold"
                  style={{ minWidth: "100px" }}
                >
                  To Date <span className="text-red">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="datePicker"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  min={today}
                  required
                />
              </div>

              <div className="col mt-4 d-flex align-items-center">
                <button
                  className="btn btn-primary mt-2 fw-bold btncolor me-2"
                  type="submit"
                >
                  Search
                </button>
                {selectedAppointments.length > 0 && (
                  <button
                    className="btn btn-primary mt-2 fw-bold btncolor ml-10 "
                    type="button"
                    onClick={handleModalToggle}
                  >
                    Start Call
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="mt-3">
        <div className="table-responsive">
          <table className="table table-striped table-hover">
            <thead className="table-primary">
              <tr>
                <th onClick={handleSelectAll} className="cursor-pointer ">
                  Select all
                </th>
                {/* <th>Provider</th> */}
                <th>First Name</th>
                <th>Last Name</th>
                <th>DOB</th>
                <th>Phone No</th>
                <th>Date</th>
                <th>Time</th>
                <th>Duration</th>
                <th>Call Status</th>
              </tr>
            </thead>
            <tbody>
              {appointments.map((appointment) => (
                <tr
                  key={appointment.appointment_id}
                  className={
                    selectedAppointments.includes(appointment.appointment_id)
                      ? "table-info"
                      : ""
                  }
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedAppointments.includes(
                        appointment.appointment_id
                      )}
                      onChange={() =>
                        handleCheckboxChange(appointment.appointment_id)
                      }
                    />
                  </td>
                  {/* <td onClick={() => summaryModal(appointment)}>
                    {appointment.provider}
                  </td> */}
                  <td onClick={() => summaryModal(appointment)}>
                    {appointment.patient?.first_name}
                  </td>
                  <td onClick={() => summaryModal(appointment)}>
                    {appointment.patient?.last_name}
                  </td>
                  <td onClick={() => summaryModal(appointment)}>
                    {appointment.patient?.dob}
                  </td>
                  <td onClick={() => summaryModal(appointment)}>
                    {appointment.patient?.phone}
                  </td>
                  <td onClick={() => summaryModal(appointment)}>
                    {appointment?.date}
                  </td>
                  <td onClick={() => summaryModal(appointment)}>
                    {appointment?.time}
                  </td>
                  <td onClick={() => summaryModal(appointment)}>
                    {appointment?.duration} mins
                  </td>
                  <td onClick={() => summaryModal(appointment)}>
                    {appointment?.call_response?.call_status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal show={showModal} onHide={handleModalToggle}>
        <Modal.Header closeButton>
          <Modal.Title>Selected Patients</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
          {selectedRows.length > 0 ? (
            <ul className="list-group">
              {selectedRows.map((appointment) => (
                <li
                  key={appointment.appointment_id}
                  className="list-group-item"
                >
                  <strong>Patient:</strong> {appointment.patient?.first_name}{" "}
                  {appointment.patient?.last_name}
                </li>
              ))}
            </ul>
          ) : (
            <p>No appointments selected.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalToggle}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleCreateAppointment}
            disabled={btnloading} // Disable button during loading
          >
            {btnloading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="ms-2">Scheduling...</span>
              </>
            ) : (
              "Start Scheduling"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={selectedSummaryModal}
        onHide={() => setSelectedSummaryModal(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Appointment Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <h5>Appointment Date</h5>
          <div>
            {selectedAppt.call_response?.appointment_confirm_time_and_date ||
              "No appointment date is available"}
          </div>
          <h5>Call End Reason</h5>
          <div>
            {formatText(selectedAppt.call_response?.ended_reason) ||
              "No end reason"}
          </div>
          <h5>Call Summary</h5>
          <div>
            {selectedAppt.call_response?.summary ||
              "No call summary is available"}
          </div>
          <h5>Success Evalution</h5>
          <div>
            {selectedAppt.call_response?.success_evaluation ||
              "No sucess evalution"}
          </div>
          <h5>Call Conversation</h5>

          <div>
            {selectedAppt.call_response?.response?.message.messages ? (
              selectedAppt.call_response.response?.message?.messages
                .filter(({ role }) => role !== "system") // Exclude system messages
                .map(({ role, message }, index) => (
                  <p key={index}>
                    <strong>{role === "bot" ? "Bot:" : "User:"}</strong>{" "}
                    {message}
                  </p>
                ))
            ) : (
              <p>No call conversation is available</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setSelectedSummaryModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {loading && <CommonSpinner />}
    </div>
  );
};

export default Schedule;
