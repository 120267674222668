// import { toast } from 'react-toastify';

// export const showToast = (type, title, message) => {
//   const options = {
//     position: "top-right",
//     autoClose: 3000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     theme: "colored"
//   };

//   const fullMessage = `${title ? `${title}: ` : ''}${message}`;

//   switch (type) {
//     case 'success':
//       toast.success(fullMessage, options);
//       break;
//     case 'error':
//       toast.error(fullMessage, options);
//       break;
//     case 'info':
//       toast.info(fullMessage, options);
//       break;
//     case 'warning':
//       toast.warn(fullMessage, options);
//       break;
//     default:
//       toast(fullMessage, options);
//       break;
//   }
// };


import toast from 'react-hot-toast';

export const showToast = (type = 'info',message) => {
  const toastOptions = {
    style: {
      borderRadius: '10px',
      background: '#333',
      color: '#fff',
    },
    icon: type === 'success' ? '✅' :
          type === 'error' ? '❌' :
          type === 'warning' ? '⚠️' :
          'ℹ️',  // default icon for 'info' or other types
  };

  // Display the toast with appropriate message and options
  toast(message, toastOptions);
};

export default showToast;
