import React from 'react'
import { useStateContext } from '../contexts/ContextProvider'
import { Link, NavLink } from 'react-router-dom'
import { MdOutlineCancel } from 'react-icons/md'
import { SiShopware } from 'react-icons/si'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { links } from '../data/dummy'
import { useSelector } from 'react-redux';
import { selectRole } from '../Redux-store/authSlice';

const Sidebar = () => {
    const { currentColor, setActiveMenu, activeMenu, screenSize } = useStateContext();
    const role = useSelector(selectRole);

    const filteredLinks = links.map(group => ({
        ...group,
        links: group.links
            .filter(link => link.roles === role)  // Filter by the exact match for the user role
            .map(link => ({
                ...link,
                pages: link.pages || [],  // Ensure pages is an array
            })),
    }));

    console.log("filteredLinks:", filteredLinks);
    console.log("role:", role);

    const handleCloseSidebar = () => {
        if (activeMenu !== undefined && screenSize <= 900) {
            setActiveMenu(false);
        }
    };

    //const activeLink = 'flex items-center gap-3 pl-5 pr-3 pt-3 pb-2.5 rounded-lg bg-white text-red  text-md m-2 ml-3 mr-3 font-bold no-underline';
    //const customLink = 'customeLink flex items-center gap-3 pl-4 pr-3 pt-3 pb-2.5 rounded-lg text-blue-900 text-md font-bold no-underline m-2 ml-3 mr-3 hover:bg-white';

    return (
        <div
        className="h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 sidebar-container"
        style={{ backgroundColor: 'rgba(30,58,138)' }}
      >
        {activeMenu && (
          <>
            <div className="flex justify-between items-center">
              <div className="flex justify-center mb-3 mt-4 bg-white w-48 rounded items-center mx-auto">
                <img src="/logo.png" alt="AZZ Medical Associates" className="w-40" />
              </div>
            </div>
            <div className="mt-10">
              {filteredLinks.map((item) => (
                <div key={item.title}>
                  {/* <p className="menu-title">{item.title || "Menu"}</p> */}
                  {item.links.map((link) => (
                    <div key={link.roles}>
                      {link.pages.map((page) => (
                        <NavLink
                          to={`/${page.name}`}
                          key={page.name}
                          onClick={handleCloseSidebar}
                          className={({ isActive }) => (isActive ? 'menu-link active-link' : 'menu-link custom-link')}
                        >
                          <span className="menu-icon">{page.icon}</span>
                          <span
                            className="capitalize"
                            style={{ fontSize: '95%', fontFamily: 'sans-serif' }}
                          >
                            {page.name}
                          </span>
                        </NavLink>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      
      
      
    );
};

export default Sidebar;





