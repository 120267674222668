import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './App.css';
import { App } from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './Redux-store/store';
import { ContextProvider } from './contexts/ContextProvider';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ContextProvider>
                <BrowserRouter> {/* Wrap App with BrowserRouter */}
                    <App />
                </BrowserRouter>
            </ContextProvider>
        </PersistGate>
    </Provider>
);
