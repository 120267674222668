import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react';

const initialState = {
  userId: null,
  role: null,
  token: null,
  isLoggedIn:null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action) {
      state.userId = action.payload.userId;
      state.role = action.payload.role;
      state.token = action.payload.token;
      state.isLoggedIn=action.payload.isLoggedIn;
    },
    logout(state) {
      state.userId = null;
      state.role = null;
      state.token = null;
      state.isLoggedIn=null;
    },
  },
});

export const { setAuth, logout } = authSlice.actions;

// Corrected selectors
export const selectRole = (state) => state.auth.role; // Selector for role
export const selectToken = (state) => state.auth.token; // Selector for token
export const selectUser = (state) => state.auth.userId; // Corrected to userId
export const selectisLogin = (state) => state.auth?.isLoggedIn;

export default authSlice.reducer;
