
import React, { useState, useEffect, useRef } from "react";
import { getaxiosRequestWithToken, postaxiosRequestWithToken } from "../../api/api";
import { FiShoppingBag, FiTrash2 } from 'react-icons/fi';
import { showToast } from '../../services/toastifyservices';
import { handleSession } from '../../api/apiSessionHandle'
import { useNavigate } from 'react-router-dom';
import CommonSpinner from '../../services/commonSpinner';
import Swal from "sweetalert2";
import './document.css';

const Document = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [AssistantName, setAssistantName] = useState("");
    const [Location, setLocation] = useState([]);
    const [location_id, setLocationId] = useState("");
    const [date, setDate] = useState("");
    const [documentdata, setdocumentdata] = useState([]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"))
        setAssistantName(user.name)
        fetchLocation();
    }, [navigate]);
    //<-----------------End Task Section------------->
    const fetchLocation = async () => {
        try {
            const response = await getaxiosRequestWithToken("locations");
            if (response.success) {
                setLocation(response.data.addresses || []);
            }
        } catch (error) {
            showToast('error', error.message);
            handleSession(error.response?.data, navigate);
        } finally {
            setLoading(false);
        }
    };

    //--------------------------on Search Button---------------------//
    const handleSearch = async (e) => {
        if (e) {
            e.preventDefault();
        }
        setLoading(true);
        const Payload = {
            location_id: location_id,
            date: date
        };
        try {
            const response = await getaxiosRequestWithToken("get-document", Payload);
            if (response.success) {
                setLoading(false);
                setdocumentdata(response.data.documents);
            }
        } catch (error) {
            showToast('error', error.message);
            setLoading(false);
            handleSession(error.response?.data, navigate);
            handleSession(error.response.data, navigate);
        }
    };

    const handleDocumentClick = (filePath) => {
        window.open(filePath, '_blank');
    };

    const handleDelete = async (documentId) => {
        Swal.fire({
          title: "Do you want to delete this?",
          showCancelButton: true,
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          icon: "question"
        }).then(async (result) => { 
          if (result.isConfirmed) {
            try {
              const params = { id: documentId };
              const response = await postaxiosRequestWithToken("delete-document", params);
      
              if (response.success) {
                showToast("success", "Document deleted successfully");
                handleSearch(); 
              } else {
                showToast("error", response.message || "Failed to delete the document");
              }
            } catch (error) {
              showToast("error", "An unexpected error occurred while deleting the document");
            }
          }
        });
      };
      

    return (
        <div className="mt-3 p-2 md:p-10 bg-gray-200">
            <div className="card">
                <span className="text-start font-bold ml-4 p-2 headingtext">Documents</span>
            </div>
            <form onSubmit={handleSearch}>
                <div className="card mt-3">
                    <div className="row mt-3 mb-2 py-2">
                        <div className="col-md-3 ml-3">
                            <label
                                htmlFor="statusSelect"
                                className="form-label font-bold mb-1" // `mb-1` to add some space between the label and the dropdown
                                style={{ display: 'block' }} // Ensure label takes up full width
                            >
                                Location <span className="text-red">*</span>
                            </label>
                            <div className="d-flex">
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    id="locationselect"
                                    value={location_id}
                                    onChange={(e) => setLocationId(e.target.value)}
                                    required
                                >
                                    <option value="">Select Location</option>
                                    {Location.map((location) => (
                                        <option key={location.location_id} value={location.location_id}>
                                            {location.city}
                                        </option>
                                    ))}
                                </select>

                            </div>
                        </div>
                        <div className="col-md-3">
                            <label
                                htmlFor="statusSelect"
                                className="form-label font-bold mb-1"
                                style={{ display: 'block' }}
                            >
                                Date <span className="text-red">*</span>
                            </label>
                            <input
                                type="date"
                                className="form-control"
                                id="datePicker"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                required
                            />
                        </div>
                        <div className="col-md-3">
                            <button className="btn btn-primary fw-bold btncolor mt-4" type="submit">Search</button>

                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="document-list">
                        {documentdata.map((doc) => (
                            <div key={doc.id} className="document-item mb-4"> {/* Adjusted margin for spacing */}
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center patient-name-container">
                                            {/* Patient Name */}
                                            <span className="patient-name h5 mb-0">{doc.patient_name}</span>

                                            {/* Actions */}
                                            <div className="d-flex">
                                                {/* View Document Button */}
                                                <button
                                                    onClick={() => handleDocumentClick(doc.file_path)}
                                                    className="btn btn-link me-2"
                                                    title="View Document"
                                                >
                                                    <FiShoppingBag size={24} />
                                                </button>

                                                {/* Delete Document Icon */}
                                                <button
                                                    onClick={() => handleDelete(doc.id)}
                                                    className="btn btn-link"
                                                    title="Delete Document"
                                                    style={{ color: 'red' }} // Red color for the icon
                                                >
                                                    <FiTrash2 size={24} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </form>
            {loading && <CommonSpinner />}


        </div>
    );
};

export default Document;
