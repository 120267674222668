import React, { useState, useEffect } from 'react';
import Received from '../../components/faxes/received';
import Sent from '../../components/faxes/sent';
import { showToast } from '../../services/toastifyservices';
import { handleSession } from '../../api/apiSessionHandle'
import CommonSpinner from '../../services/commonSpinner';
import { getaxiosRequestWithToken } from "../../api/api";


const Faxes = () => {
  const [subTab, setSubTab] = useState('received');
  const [loading, setLoading] = useState(true);
  const [faxHistory, setfaxHistory] = useState([]);

  useEffect(() => {
    fetchLocation();
  }, []);

  const fetchLocation = async () => {
    try {
      setLoading(true);
      const response = await getaxiosRequestWithToken("fax-history");
      if (response.success) {
        var data = response.data.data.data;
        console.log("data1", data.data)
       // const filteredRows = data.data.filter(row => row.status === "Received");
        setfaxHistory(data);
      }
    } catch (error) {
      showToast('error', error.message);
      handleSession(error.response?.data, navigate);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-3 p-2 md:p-10 bg-gray-200">
      <div className="card">
        <span className="text-start font-bold ml-4 p-2 headingtext">Faxes</span>
      </div>
      <div className='card mt-2'>
        <div className='card-body '>
          <button
            className={`py-2 px-6 rounded-lg hover:scale-105 mx-2 ${subTab === 'received' ? 'bg-blue-900 text-white' : 'bg-gray-300 text-black'}`}
            onClick={() => setSubTab('received')}
          >
            Received
          </button>
          <button
            className={`py-2 px-6 rounded-lg hover:scale-105 mx-2 ${subTab === 'sent' ? 'bg-blue-900 text-white' : 'bg-gray-300 text-black'}`}
            onClick={() => setSubTab('sent')}
          >
            Sent
          </button>
        </div>
      </div>

      <div className="">
        <div >
        {subTab === 'received' && <Received faxHistory={faxHistory} loading={loading} />}
        </div>
        <div className="col">
        {subTab === 'sent' && <Sent faxHistory={faxHistory} loading={loading} />}
        </div>
      </div>
      {loading && <CommonSpinner />}
    </div>
  );
};

export default Faxes;
