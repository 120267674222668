import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAuth } from '../../Redux-store/authSlice';
import { validateToken } from "../../api/api";
import { selectisLogin } from '../../Redux-store/authSlice';
import { useSelector } from 'react-redux';

function Verification() {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const [isVerifying, setIsVerifying] = useState(false); // Track verification state
  // const isLoggedIn = useSelector(selectisLogin);

  // useEffect(() => {
  //     verifyToken();
    
  // }); 

  // const verifyToken = async () => {
  //   setIsVerifying(true); // Prevent duplicate requests
  //   const token = localStorage.getItem('token');
  //   debugger
  //   try {
  //     const response = await validateToken(token); // Pass token if needed
  //     if (response.success) {
  //       dispatch(
  //         setAuth({
  //           isLoggedIn: true,
  //         })
  //       );
  //       navigate("/assistant");
  //     } else {
  //       //localStorage.removeItem("token");
  //       //dispatch(setAuth({ isLoggedIn: false }));
  //       navigate("/Signin");
  //     }
  //   } catch (error) {
  //     //localStorage.removeItem("token");
  //     //dispatch(setAuth({ isLoggedIn: false }));
  //     navigate("/Signin");
  //   } finally {
  //     setIsVerifying(false); // Reset verification state
  //   }
  // };


  return (
   
    <div className=" h-[100vh] w-[100vw] flex justify-center items-center flex-col">
      <div className="mb-10 bg-white w-48 rounded mx-auto flex justify-center">
        <img
          src="/logo.png"
          alt="AZZ Medical Associates"
          className="w-40 h-30"
        />
      </div>
      <div className="flex justify-center items-center py-4">
        <div className="text-center py-4">
          <span className="loading loading-bars loading-lg"></span>
        </div>
      </div>
      <h2 className="text-blue-800 font-extrabold text-center">
        Please wait, verifying you.....
      </h2>
    </div>
  );

}
export default Verification;
