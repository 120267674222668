import React, { useState } from "react";
import DateInput from "../../components/DateInput";
import { PaginationControls } from "../../components/pagination"

const Sent = ({ faxHistory, loading }) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([false, false]);
  const [DateFrom, setDateFrom] = useState(new Date());
  const [DateTo, setDateTo] = useState(new Date());
  const [currentPage, setCurrentPage] = useState();
  const [rowsPerPage, setRowsPerPage] = useState();
  const [totalPages, setTotalPages] = useState();
  const handleSelectAllChange = () => {
    setSelectAll((prevState) => {
      const newSelectAll = !prevState;
      setSelectedItems([newSelectAll, newSelectAll]);
      return newSelectAll;
    });
  };

  // Function to handle individual checkbox change
  const handleCheckboxChange = (index) => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems[index] = !newSelectedItems[index];
    setSelectedItems(newSelectedItems);
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      fetchPatients(page);
    }
  };
  //! handle rows per page
  const handleRowsPerPageChange = (e) => {
    const newLimit = parseInt(e.target.value, 10);
    setRowsPerPage(newLimit);
    fetchPatients(currentPage, newLimit);
  };

  return (
    <>
      {/* <div className="flex flex-col sm:flex-row items-center gap-4 mb-6 border rounded-xl p-4 bg-gradient-to-r shadow-lg mt-2 bg-white">
        <DateInput label="Date From:" date={DateFrom} setDate={setDateFrom} />
        <DateInput label="Date To:" date={DateTo} setDate={setDateTo} />
        <div className="flex flex-col sm:flex-row items-center gap-4">
          <select
            id="statusSelect"
            className="bg-white text-black py-2 px-4 border rounded-lg w-full sm:w-auto"
          >
            <option value="">All</option>
            <option value="">Sent</option>
            <option value="">Send Failed</option>
            <option value="">Sending</option>
            <option value="">Scheduled</option>
            <option value="">Waiting for Conversion</option>
            <option value="">Waiting for Coverpage</option>
          </select>
        </div>

        <button className="bg-blue-900 text-white py-2 px-6 rounded-full hover:bg-blue-800 transition duration-300 ease-in-out transform hover:scale-105">
          Search
        </button>
      </div> */}
      <div className="table-container">
        <table className="table table-striped table-hover">
          <thead className="table-header">
            <tr>
              <th>Sender Fax No</th>
              <th>Receiver Fax No</th>
              <th>Status</th>
              <th>Status Text</th>
              <th>Date/Time Received</th>
              <th>Message Pages</th>
              <th>Message Price</th>
              <th>Preview</th>
            </tr>
          </thead>
          <tbody className="table-body">
          {loading ? null : (
            faxHistory.data.length > 0 ? (
              faxHistory.data
                .filter(row => row.status === "Sent")
                .map((row, index) => (
                  <tr key={index}>
                    <td>{row.from}</td>
                    <td>{row.to}</td>
                    <td className={row.status === "Received" ? "text-success" : "text-danger"}>
                      {row.status}
                    </td>
                    <td>{row.status_text}</td>
                    <td>{new Date(row.date_added * 1000).toLocaleString()}</td>
                    <td>{row.message_pages}</td>
                    <td>{row.message_price}</td>
                    <td>
                      <a href={row._file_url} target="_blank" rel="noopener noreferrer">
                        View
                      </a>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="10">No fax history available</td>
              </tr>
            )
          )}
           </tbody>
        </table>

      </div>
      {loading ? null : (
        <div className="pagination-controls">
          <PaginationControls
            currentPage={faxHistory.current_page}
            totalPages={Math.ceil(faxHistory.total / faxHistory.per_page)}
            onPageChange={(page) => handlePageChange(page)}
            rowsPerPage={faxHistory.per_page}
            onLimitPerPage={handleRowsPerPageChange}
          />
        </div>
      )}
    </>
  );
};

export default Sent;
