import React, { useEffect, useState } from "react";
import axios from "axios";

const WebSocketComponent = () => {
  const [pcmData, setPcmData] = useState(new Uint8Array(0));
  const [messageLog, setMessageLog] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [audioPlaybackUrl, setAudioPlaybackUrl] = useState(null);
  const [socketUrl, setSocketUrl] = useState("");

  const initiateCall = async () => {
    try {
      const response = await axios.post(
        "https://azzportal.com/dev/public/api/v3/assistant_api/create-call",
        {
          phone: "+923090851306",
          variable_values: {
            name: "Muhammad Zaheer",
            provider_name: "Muhammad Ramzan",
            start_time: "2024-12-20T10:30:00-05:00",
            appointment_id: "10121149281879341231211",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 679|ZrJViMpZszy7M9kwZbwEVo8xsiuGPD0ibqdAfMFVecf394c5`,
          },
        }
      );
      setSocketUrl(response.data.monitor.listenUrl);
    } catch (error) {
      console.error("Error initiating call:", error);
    }
  };

  useEffect(() => {
    let ws;
    let retryCount = 0;
    const maxRetries = 10;

    const setupWebSocket = () => {
      if (!socketUrl) return;

      ws = new WebSocket(socketUrl);

      ws.onopen = () => {
        setConnectionStatus(true);
        retryCount = 0;
        setSocketUrl("");
      };

      ws.onmessage = (event) => {
        if (event.data instanceof Blob) {
          const reader = new FileReader();
          reader.onload = () => {
            const arrayBuffer = new Uint8Array(reader.result);
            setPcmData((prevData) => {
              const combinedData = new Uint8Array(
                prevData.length + arrayBuffer.length
              );
              combinedData.set(prevData);
              combinedData.set(arrayBuffer, prevData.length);
              return combinedData;
            });
          };
          reader.readAsArrayBuffer(event.data);
        } else {
          try {
            const parsedMessage = JSON.parse(event.data);
            setMessageLog((prevMessages) => [...prevMessages, parsedMessage]);
          } catch (error) {
            console.error("Error parsing WebSocket message:", error);
          }
        }
      };

      ws.onclose = () => {
        console.error("WebSocket closed");
        setConnectionStatus(false);
        retryWebSocketConnection();
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        ws.close();
      };
    };

    const retryWebSocketConnection = () => {
      if (retryCount < maxRetries) {
        setTimeout(() => {
          retryCount++;
          console.log(`Retrying WebSocket connection: Attempt ${retryCount}`);
          setupWebSocket();
        }, 2000);
      } else {
        console.warn(
          "Maximum retry attempts reached. Stopping retries for this URL."
        );
      }
    };

    if (socketUrl && !connectionStatus) {
      setupWebSocket();
    }

    return () => {
      if (ws) ws.close();
    };
  }, [socketUrl, connectionStatus]);

  const convertPcmToWav = (pcmData, sampleRate = 16000, channels = 2) => {
    const header = new ArrayBuffer(44);
    const view = new DataView(header);

    const writeHeaderString = (offset, str) => {
      for (let i = 0; i < str.length; i++) {
        view.setUint8(offset + i, str.charCodeAt(i));
      }
    };

    writeHeaderString(0, "RIFF");
    view.setUint32(4, 36 + pcmData.length, true);
    writeHeaderString(8, "WAVE");
    writeHeaderString(12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, channels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * channels * 2, true);
    view.setUint16(32, channels * 2, true);
    view.setUint16(34, 16, true);
    writeHeaderString(36, "data");
    view.setUint32(40, pcmData.length, true);

    const wavBuffer = new Uint8Array(header.byteLength + pcmData.length);
    wavBuffer.set(new Uint8Array(header));
    wavBuffer.set(pcmData, header.byteLength);

    return wavBuffer;
  };

  const generateAudioPlaybackUrl = () => {
    const wavFile = convertPcmToWav(pcmData);
    const blob = new Blob([wavFile], { type: "audio/wav" });
    return URL.createObjectURL(blob);
  };

  return (
    <div className="flex flex-col items-start p-10">
      <button onClick={initiateCall} className="bg-red-400 py-2 px-2 rounded">
        Initiate Call
      </button>
      <h2>WebSocket Status</h2>
      <p>{connectionStatus ? "Connected" : "Disconnected"}</p>

      <h3>Messages</h3>
      <ul>
        {messageLog.map((msg, index) => (
          <li key={index}>{JSON.stringify(msg)}</li>
        ))}
      </ul>

      <div className="flex gap-4">
        <button
          className="bg-yellow-700 p-2 rounded-md"
          onClick={() => setAudioPlaybackUrl(generateAudioPlaybackUrl())}
        >
          Generate Audio
        </button>
      </div>

      {audioPlaybackUrl && (
        <audio controls>
          <source src={audioPlaybackUrl} type="audio/wav" />
          Your browser does not support the audio tag.
        </audio>
      )}
    </div>
  );
};

export default WebSocketComponent;
