import React from "react";

export const PaginationControls = ({
  currentPage,
  totalPages,
  onPageChange,
  rowsPerPage,
  onLimitPerPage,
}) => {
  const generatePages = () => {
    const pages = [];
    if (currentPage <= 5) {    
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
      if (totalPages > 5) {
        pages.push("...");
        pages.push(totalPages);
      }
    } else if (currentPage > totalPages - 5) {
      pages.push(1);
      pages.push("...");
      for (let i = totalPages - 5; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      pages.push("...");
      const startPage = currentPage - 2;
      const endPage = currentPage + 2;
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      pages.push("...");
      pages.push(totalPages);
    }

    return pages;
  };
  const pages = generatePages();

  return (
    <div className="flex w-full  mt-4 px-10">
      <div className="flex justify-center items-center  w-full">
        <button
          className={`mx-2 px-3 py-2 rounded ${
            currentPage === 1
              ? "bg-gray-200 text-gray-500 cursor-not-allowed"
              : "bg-gray-200 text-gray-800 hover:bg-blue-500 hover:text-white"
          }`}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </button>

        {pages.map((page, index) =>
          page === "..." ? (
            <span key={index} className="mx-2 px-3 py-2 text-gray-500">
              ...
            </span>
          ) : (
            <button
              key={index}
              className={`mx-1 px-4 py-2 rounded ${
                currentPage === page
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-800 hover:bg-blue-500 hover:text-white"
              }`}
              onClick={() => onPageChange(page)}
            >
              {page}
            </button>
          )
        )}

        <button
          className={`mx-2 px-3 py-2 rounded ${
            currentPage === totalPages
              ? "bg-gray-200 text-gray-500 cursor-not-allowed"
              : "bg-gray-200 text-gray-800 hover:bg-blue-500 hover:text-white"
          }`}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
            />
          </svg>
        </button>
      </div>
      {/* <div className="flex justify-end items-center w-[200px] space-x-3 ">
        <h2 className="text-lg">Page Size:</h2>
        <select
          className="px-2 py-2 bg-gray-200 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-600 "
          value={rowsPerPage}
          onChange={onLimitPerPage}
        >
          <option defaultValue={25}>25</option>
          <option value={50}>50</option>
          <option value={75}>75</option>
          <option value={100}>100</option>
        </select>
      </div> */}
    </div>
  );
};
