import React, { useState, useEffect, useRef } from "react";
import {
  getaxiosRequestWithToken,
  postaxiosRequestWithToken,
} from "../../api/api";
import { Accordion, Modal, Button } from "react-bootstrap";
import { showToast } from "../../services/toastifyservices";
import { handleSession } from "../../api/apiSessionHandle";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../../services/commonSpinner";
import "./appoinment.css";
import Pusher from "pusher-js";

const Appointment = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("pending");
  const [appointmentData, setappoientmentdata] = useState([]);
  const [patientData, setPatientData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isbtnLoading, setbtnIsLoading] = useState(false);
  const [date, setDate] = useState("");
  const [errors, setErrors] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setDate(today);
  }, []);

  //! pusher implementation ....
  useEffect(() => {
    var pusher = new Pusher("f5d7f359a1682ae86bd6", {
      cluster: "mt1",
    });

    var channel = pusher.subscribe("patient-channel");
    channel.bind("patient-event", function (data) {
      setRefresh(true);
    });
    return () => {
      channel.unbind("patient-event");
      pusher.unsubscribe("patient-channel");
    };
  }, []);

  const handleShowModal = (appointment) => {
    const patient = appointment.patient || {};
    setPatientData({
      first_name: patient.name || "",
      last_name: "",
      date_of_birth: "",
      phone_number: "",
      email_address: patient.email || "",
      gender: "",
      insurance_carrier: appointment.insurance?.name || "",
      insurance_plan: "",
      insurance_group_number: "",
      insurance_member_id: "",
    });
    setShowModal(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPatientData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };
  const savePatient = async () => {
    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const Payload = {
          first_name: patientData.first_name,
          last_name: patientData.last_name,
          date_of_birth: patientData.date_of_birth,
          phone_number: patientData.phone_number,
          email_address: patientData.email_address,
          gender: patientData.gender,
          insurance_carrier: patientData.insurance_carrier,
          insurance_plan: patientData.insurance_plan,
          insurance_group_number: patientData.insurance_group_number,
          insurance_member_id: patientData.insurance_member_id,
        };
        setLoading(true);
        setbtnIsLoading(true);
        const response = await postaxiosRequestWithToken(
          "create-patient",
          Payload
        );
        if (response.success) {
          showToast("success", response.data.message);
        }
      } catch (error) {
        //debugger;
        showToast("error", error.message);
        setLoading(false);
        setbtnIsLoading(false);
      } finally {
        setLoading(false);
        setbtnIsLoading(false);
        setShowModal(false);
      }
    }
  };

  const validate = () => {
    const validationErrors = {};
    if (!patientData.first_name)
      validationErrors.first_name = "First Name is required";
    if (!patientData.last_name)
      validationErrors.last_name = "Last Name is required";
    if (!patientData.date_of_birth)
      validationErrors.date_of_birth = "Date of Birth is required";
    if (!patientData.phone_number)
      validationErrors.phone_number = "Phone Number is required";
    if (!patientData.email_address)
      validationErrors.email_address = "Email Address is required";
    if (!patientData.gender) validationErrors.gender = "Gender is required";
    if (!patientData.insurance_carrier)
      validationErrors.insurance_carrier = "Insurance Carrier is required";
    if (!patientData.insurance_plan)
      validationErrors.insurance_plan = "Insurance Plan is required";
    if (!patientData.insurance_group_number)
      validationErrors.insurance_group_number = "Group Number is required";
    if (!patientData.insurance_member_id)
      validationErrors.insurance_member_id = "Member ID is required";

    return validationErrors;
  };

  const handleCloseModal = () => setShowModal(false);

  //--------------------------on Search Button---------------------//
  const handleSearch = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const Payload = {
      status: status,
      date: date,
    };
    setLoading(true);

    try {
      const response = await getaxiosRequestWithToken(
        "get-appointments",
        Payload
      );
      if (response.success) {
        //debugger;
        setLoading(false);
        setappoientmentdata(response.data.appointments);
        setLoading(false);

        console.log("response", response.data.appointments);
      }
    } catch (error) {
      showToast("error", error.message);
      setLoading(false);
      handleSession(error.response?.data, navigate);
      handleSession(error.response.data, navigate);
    }
  };

  useEffect(() => {
    if (refresh) {
      handleSearch();
      setRefresh(false);
    }
  }, [refresh]);

  const handleDocumentClick = (filePath) => {
    console.log("filePath", filePath);
    //window.open(filePath, '_blank');
  };
  return (
    <div className="mt-3 p-2 md:p-10 bg-gray-200">
      <div className="card">
        <span className="text-start font-bold ml-4 p-2 headingtext">
          Appointments
        </span>
      </div>

      <form onSubmit={handleSearch}>
        <div className="card mt-3">
          <div className="row mt-3 mb-2 py-2">
            <div className="col-md-3 ml-3">
              <label
                htmlFor="statusSelect"
                className="form-label font-bold mb-1" // `mb-1` to add some space between the label and the dropdown
                style={{ display: "block" }} // Ensure label takes up full width
              >
                Status <span className="text-red">*</span>
              </label>
              <div className="d-flex">
                <select
                  className="form-select me-2" // Added margin to the right for spacing between dropdown and button
                  aria-label="Default select example"
                  id="statusSelect"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  required
                >
                  <option value="pending">Pending</option>
                  <option value="completed">Completed</option>
                  <option value="confirmed">Confirmed</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </div>
            </div>
            <div className="col-md-3">
              <label
                htmlFor="statusSelect"
                className="form-label font-bold mb-1"
                style={{ display: "block" }}
              >
                Date <span className="text-red">*</span>
              </label>
              <input
                type="date"
                className="form-control"
                id="datePicker"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
              />
            </div>
            <div className="col-md-3 mt-4">
              <button
                className="btn btn-primary fw-bold btncolor"
                type="submit"
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          {appointmentData.length > 0 ? (
            appointmentData.map((appointment) => (
              <div className="mb-3" key={appointment.id}>
                <Accordion>
                  <Accordion.Item eventKey={appointment.id}>
                    <Accordion.Header>
                      <div className="patient-info">
                        <div className="row mb-2 align-items-center">
                          <div className="col-auto">
                            <strong className="text-primary">
                              Patient Name:
                            </strong>
                          </div>
                          <div className="col">
                            <p className="mb-0">
                              {appointment.patient?.name || "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="row mb-2 align-items-center">
                          <div className="col-auto">
                            <strong className="text-primary">
                              Patient Email:
                            </strong>
                          </div>
                          <div className="col">
                            <p className="mb-0">
                              {appointment.patient?.email || "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="appointment-container">
                        <div className="left-section">
                          <h5>Date and Time</h5>
                          <p>
                            <strong>Date:</strong> {appointment.date}
                          </p>
                          <p>
                            <strong>Time:</strong> {appointment.time}
                          </p>
                          <p>
                            <strong>Visit Type:</strong>{" "}
                            {appointment.visit_type}
                          </p>
                          <p>
                            <strong>Mode:</strong> {appointment.mode}
                          </p>
                          <p>
                            <strong>Status:</strong> {appointment.status}
                          </p>
                        </div>
                        <div className="right-section">
                          <h5>Address</h5>
                          <p>
                            <strong>Location:</strong>{" "}
                            {appointment.address?.address || "N/A"}
                          </p>
                          <p>
                            <strong>City:</strong>{" "}
                            {appointment.address?.city || "N/A"}
                          </p>
                          <p>
                            <strong>State:</strong>{" "}
                            {appointment.address?.state || "N/A"}
                          </p>
                        </div>
                        <div className="right-section">
                          <h5>Insurance</h5>
                          <p>
                            <strong>Insurance Name:</strong>{" "}
                            {appointment.insurance?.name || "N/A"}
                          </p>
                          <p>
                            <strong>Priority:</strong>{" "}
                            {appointment.insurance?.priority || "N/A"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-end">
                          <Button
                            className="btn btn-primary fw-bold btncolor mb-3"
                            onClick={() => handleShowModal(appointment)}
                          >
                            Create Patient
                          </Button>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            ))
          ) : (
            <div className="custom-style">{/* <p>No Records Found.</p> */}</div>
          )}
        </div>
      </form>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <div className="modal-custom-content">
          <Modal.Header closeButton className="sticky-top modal-custom-header">
            <Modal.Title>Create Patient</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-custom-body">
            <form>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    className="form-control"
                    value={patientData.first_name}
                    onChange={handleInputChange}
                  />
                  {errors.first_name && (
                    <div className="text-danger">{errors.first_name}</div>
                  )}
                </div>

                <div className="col-md-6 mt-3">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    className="form-control"
                    value={patientData.last_name}
                    onChange={handleInputChange}
                  />
                  {errors.last_name && (
                    <div className="text-danger">{errors.last_name}</div>
                  )}
                </div>

                <div className="col-md-6 mt-3">
                  <label className="form-label">Date of Birth</label>
                  <input
                    type="date"
                    name="date_of_birth"
                    className="form-control"
                    value={patientData.date_of_birth}
                    onChange={handleInputChange}
                  />
                  {errors.date_of_birth && (
                    <div className="text-danger">{errors.date_of_birth}</div>
                  )}
                </div>

                <div className="col-md-6 mt-3">
                  <label className="form-label">Phone Number</label>
                  <input
                    type="text"
                    name="phone_number"
                    className="form-control"
                    value={patientData.phone_number}
                    onChange={handleInputChange}
                  />
                  {errors.phone_number && (
                    <div className="text-danger">{errors.phone_number}</div>
                  )}
                </div>

                <div className="col-md-6 mt-3">
                  <label className="form-label">Email Address</label>
                  <input
                    type="email"
                    name="email_address"
                    className="form-control"
                    value={patientData.email_address}
                    onChange={handleInputChange}
                  />
                  {errors.email_address && (
                    <div className="text-danger">{errors.email_address}</div>
                  )}
                </div>

                <div className="col-md-6 mt-3">
                  <label className="form-label">Gender</label>
                  <select
                    name="gender"
                    className="form-control"
                    value={patientData.gender}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors.gender && (
                    <div className="text-danger">{errors.gender}</div>
                  )}
                </div>

                <div className="col-md-6 mt-3">
                  <label className="form-label">Insurance Carrier</label>
                  <input
                    type="text"
                    name="insurance_carrier"
                    className="form-control"
                    value={patientData.insurance_carrier}
                    onChange={handleInputChange}
                  />
                  {errors.insurance_carrier && (
                    <div className="text-danger">
                      {errors.insurance_carrier}
                    </div>
                  )}
                </div>

                <div className="col-md-6 mt-3">
                  <label className="form-label">Insurance Plan</label>
                  <input
                    type="text"
                    name="insurance_plan"
                    className="form-control"
                    value={patientData.insurance_plan}
                    onChange={handleInputChange}
                  />
                  {errors.insurance_plan && (
                    <div className="text-danger">{errors.insurance_plan}</div>
                  )}
                </div>

                <div className="col-md-6 mt-3">
                  <label className="form-label">Group Number</label>
                  <input
                    type="text"
                    name="insurance_group_number"
                    className="form-control"
                    value={patientData.insurance_group_number}
                    onChange={handleInputChange}
                  />
                  {errors.insurance_group_number && (
                    <div className="text-danger">
                      {errors.insurance_group_number}
                    </div>
                  )}
                </div>

                <div className="col-md-6 mt-3">
                  <label className="form-label">Member ID</label>
                  <input
                    type="text"
                    name="insurance_member_id"
                    className="form-control"
                    value={patientData.insurance_member_id}
                    onChange={handleInputChange}
                  />
                  {errors.insurance_member_id && (
                    <div className="text-danger">
                      {errors.insurance_member_id}
                    </div>
                  )}
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className="sticky-bottom modal-custom-footer">
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button
              className="btn btn-primary fw-bold btncolor"
              onClick={savePatient}
              disabled={isbtnLoading} // Disable button while loading
            >
              {isbtnLoading ? (
                <>
                  <div
                    className="spinner-border text-light spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <span className="ms-2">Saving Patient...</span>{" "}
                  {/* Optional: text next to spinner */}
                </>
              ) : (
                "Save Patient"
              )}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      {loading && <CommonSpinner />}
    </div>
  );
};

export default Appointment;
