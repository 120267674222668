import axios from 'axios';

const BASE_URL = 'https://azzportal.com/admin/public/api/v3/assistant_api';

// Helper function to handle API responses
const handleResponse = async (response) => {
  const data = await response.json();
  return { data, status: response.status };
};

const axiosInstance = axios.create({
  baseURL: BASE_URL, // Replace with your actual base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getaxiosRequestWithToken = async (endpoint, params = {}) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get(endpoint, {
    params: params,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  return { success: true, data: response.data };
};

export const getaxiosRequestWithoutToken = async (endpoint, params = {}) => {
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
  };
  const response = await axiosInstance.get(endpoint, {
    params: params,
    headers: headers,
  });
  return { success: true, data: response.data };
};

export const validateToken = async () => {
  const access_token = localStorage.getItem('token');
  const response = await axios.get(`${BASE_URL}/info`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });
  return { success: true, data: response.data };
};

export const postaxiosRequestWithToken = async (endpoint, body = {}) => {
  //debugger
  const token = localStorage.getItem('token');
    
    // Make the POST request using axios
    const response = await axiosInstance.post(`${BASE_URL}/${endpoint}`, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Pass token in headers
      },
    });

  return { success: true, data: response.data };
};

export const postaxiosRequest = async (endpoint, body = {}) => {
  //debugger

  const response = await axios.post(`${BASE_URL}/${endpoint}`, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return { success: true, data: response.data };
};
export const deleteAxiosRequestWithToken = async (endpoint, params = {}) => {
  const token = localStorage.getItem('token');
  debugger
  const queryString = new URLSearchParams(params).toString();

  const response = await axiosInstance.post(`${BASE_URL}/${endpoint}?${queryString}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`, // Pass token in headers
    },
  });
  
  return { success: true, data: response.data };
};


// // GET Method WITH Authorization Token
// export const getRequestWithToken = async (endpoint, params = {}) => {
//   debugger
//   const token = localStorage.getItem('token');
//   const url = new URL(`${BASE_URL}/${endpoint}`);
//   // Add query parameters if any
//   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
//   const response = await fetch(url, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${token}`, // Pass token in headers
//     },
//   });
//   if (!response.ok) {
//     // If the response is not ok, parse the error message and throw it as an object
//     const errorData = await response.json();
//     const errorObj = { data: JSON.stringify(errorData) }; // Stringify the error data
//     throw errorObj;
//   }
//   return handleResponse(response);
// };

// // GET Method WITHOUT Authorization Token
// export const getRequest = async (endpoint, params = {}) => {
//   const url = new URL(`${BASE_URL}/${endpoint}`);
//   // Add query parameters if any
//   Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
//   const response = await fetch(url, {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
//   return handleResponse(response);
// };

// // POST Method WITH Authorization Token
// export const postRequestWithToken = async (endpoint, body = {}) => {
//   const token = localStorage.getItem('token');
//   debugger
//   const response = await fetch(`${BASE_URL}/${endpoint}`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${token}`, // Pass token in headers
//     },
//     body: JSON.stringify(body),
//   });

//   return handleResponse(response);
// };

// // POST Method WITHOUT Authorization Token
// export const postRequest = async (endpoint, body = {}) => {
//   const response = await fetch(`${BASE_URL}/${endpoint}`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(body),
//   });
//   return handleResponse(response);
// };

// // DELETE Method WITH Authorization Token
// export const deleteRequestWithToken = async (endpoint, token) => {
//   const response = await fetch(`${BASE_URL}/${endpoint}`, {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${token}`, // Pass token in headers
//     },
//   });

//   return handleResponse(response);
// };

// // DELETE Method WITHOUT Authorization Token
// export const deleteRequest = async (endpoint) => {
//   const response = await fetch(`${BASE_URL}/${endpoint}`, {
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });

//   return handleResponse(response);
// };


