import React, { useState, useEffect } from "react";
import { Footer, Navbar, Sidebar, ThemeSettings } from "./components";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { ToastContainer, toast } from "react-toastify";
import { useStateContext } from "./contexts/ContextProvider";
import Dashboard from "./pages/DashBoard/dashboard";
import Assistant from "./pages/Assistant/assistant";
import ManageClaim from "./pages/Manage-Claims/manage-claims";
import Faxes from "./pages/faxes/faxes"
import Signin from "./pages/signin/signin";
import ProtectedRoute from "./services/Protected";
import { Toaster } from "react-hot-toast";
import UnAuthorized from "./pages/UnAuthorized/un-authorized";
import { useSelector } from "react-redux";
import { selectisLogin, setAuth } from "./Redux-store/authSlice";
import Verification from "./pages/Verification/verification";
import { validateToken } from "./api/api";
import Document from "./pages/Documents/document";
import Appointment from "./pages/Appointment/appoinment";
import "./App.css";
import Schedule from "./pages/Schedule/schedule";
import WebSocketComponent from "./WebSocketComponent";
import icon from "../src/favicon.ico";
import addNotification, { Notifications } from "react-push-notification";
import Pusher from "pusher-js";

export const App = () => {
  const { activeMenu } = useStateContext();
  const isLoggedIn = useSelector(selectisLogin);
  var user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  //! puhser to show push notifications
  useEffect(() => {
    var pusher = new Pusher("f5d7f359a1682ae86bd6", {
      cluster: "mt1",
    });

    var channel = pusher.subscribe("patient-channel");
    channel.bind("patient-event", function (data) {
      console.log("pusher data", data);
      addNotification({
        title: `${data?.patient_name}  has created an appointment.`,
        icon: `${icon}`,
        theme: "darkblue",
        native: true,
        vibrate: 3,
      });
    });

    // Cleanup function to avoid multiple subscriptions
    return () => {
      channel.unbind("patient-event");
      pusher.unsubscribe("patient-channel");
    };
  }, []); // Dependency array ensures this runs once

  useEffect(() => {
    verifyToken();
  }, []);

  const verifyToken = async () => {
    try {
      const response = await validateToken();
      if (response.success) {
        if (location.pathname === "/" || location.pathname === "/Signin") {
          navigate("/dashboard");
        }
      } else {
        navigate("/Signin");
      }
    } catch (error) {
      navigate("/Signin");
    } finally {
      setLoading(false); // Set loading to false after verification
    }
  };

  if (loading) {
    return (
      <div>
        <Verification />
      </div>
    ); // Show a loading indicator
  }
  return (
    <Routes>
      {/* Pass activeMenu to Layout */}
      <Route path="/" element={<Signin />} />
      <Route path="/Signin" element={<Signin />} />
      <Route path="/UnAuthorized" element={<UnAuthorized />} />
      <Route path="/call" element={<WebSocketComponent />} />

      <Route
        path="assistant"
        element={
          <ProtectedRoute>
            <Layout activeMenu={activeMenu}>
              <Assistant />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="manage-claim"
        element={
          <ProtectedRoute>
            <Layout activeMenu={activeMenu}>
              <ManageClaim />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="document"
        element={
          <ProtectedRoute>
            <Layout activeMenu={activeMenu}>
              <Document />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="dashboard"
        element={
          <ProtectedRoute>
            <Layout activeMenu={activeMenu}>
              <Dashboard />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="appointment"
        element={
          <ProtectedRoute>
            <Layout activeMenu={activeMenu}>
              <Appointment />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="scheduling"
        element={
          <ProtectedRoute>
            <Layout activeMenu={activeMenu}>
              <Schedule />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="faxes"
        element={
          <ProtectedRoute>
            <Layout activeMenu={activeMenu}>
              <Faxes />
            </Layout>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

const Layout = ({ children, activeMenu }) => {
  return (
    <div className="flex relative dark:bg-main-dark-bg">
      <Notifications />
      {/* Sidebar */}
      {activeMenu ? (
        <div className="w-64 fixed sidebar dark:bg-secondary-dark-bg bg-white z-8">
          {" "}
          {/* Adjusted width here */}
          <Sidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <Sidebar />
        </div>
      )}

      {/* Main content area */}
      <div
        className={
          activeMenu
            ? "dark:bg-main-dark-bg bg-main-bg min-h-screen md:ml-64 w-full" // Adjusted margin left
            : "bg-main-bg dark:bg-main-dark-bg w-full min-h-screen flex-2"
        }
      >
        {/* Navbar - Changed to sticky */}
        <div className="sticky top-0 dark:bg-secondary-dark-bg bg-white w-full z-50">
          <Toaster position="top-right" reverseOrder={false} />
          <Navbar />
        </div>

        {/* Main content */}
        <div className="">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
