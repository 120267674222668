import React, { useState } from 'react'
import { useStateContext } from '../contexts/ContextProvider'
import { Button } from '.'
import { userProfileData } from '../data/dummy'
import { MdOutlineCancel } from 'react-icons/md'
import { AiOutlineLogout } from "react-icons/ai";
import avatar from '../data/avatar.jpg'
import { useNavigate } from 'react-router-dom';
import { postRequest, postaxiosRequestWithToken } from "../api/api";
import { handleSession } from '../api/apiSessionHandle'

const UserProfile = () => {
  const { currentColor } = useStateContext();
  const [loading, setLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const navigate = useNavigate();

  const handleLogout = async (e) => {
    debugger
    setLoading(true);
    if (e) {
      e.preventDefault();
    }
    const Payload = {
      token: token,
    };


    try {
      const response = await postaxiosRequestWithToken("logout", Payload);
      if (response.success) {
        setLoading(false);
        const result = response.data;
        if (result.status) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/");
        } else {
          showToast("error", "", result.message);
        }
      }
    } catch (error) {
debugger
      console.error("Error:", error);
      handleSession(error.response?.data, navigate);

    }
  }

  return (
    <div className="nav-item right-1 top-16 absolute bg-white dark:bg-[#42464D] p-2 md:w-15 hover:bg-light-gray">
      <div className="justify-between flex items-center">
        {/* <p className="font-semibold text-lg dark:text-gray-200">User Profile</p> */}
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      {/* <div className="flex gap-3 items-center mt-2 border-color">
          <img className="rounded-full h-12 w-12" src={avatar} alt="user-profile" />
          <div>
            <p className="font-semibold text-xl text-gray-200">{user?.name}</p>
            <p className="text-gray-500 text-sm dark:text-gray-400">{user?.role}</p>
            <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">{user?.email}</p>
          </div>
        </div> */}
      <div>
        {/* Additional user profile content can go here */}
      </div>
      <hr />
      <div className="justify-between flex items-center mt-2 hover:bg-light-gray">
        <p className="font-semibold text-lg dark:text-gray-200 p-2">Log-out</p>
        <Button
          icon={<AiOutlineLogout />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
          onClick={handleLogout}
        />
      </div>
    </div>
  );
};

export default UserProfile;
