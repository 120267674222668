import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { links } from '../data/dummy';
import { useSelector } from 'react-redux';
import { selectRole } from '../Redux-store/authSlice';

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  console.log("links",links)
  const role = useSelector(selectRole);
  const location = useLocation();
  if (!token) {
    return <Navigate to="/" replace />;
  }
  
  if (role == null || role == undefined) {
    return <Navigate to="/" replace />;
  }
  // const route = links.flatMap(group => group.links).find(link => `/${link.name}` === location.pathname);
  const route = links
    .flatMap(group => group.links || []) // Flatten the 'links' array
    .filter(link => link.roles?.toLowerCase() === role.toLowerCase()) // Filter links by role
    .flatMap(link => link.pages.map(page => ({ ...page, roles: link.roles })) || []) // Flatten pages
    .find(page => `/${page.name}` === location.pathname);
  
  if (!route) {
    return <Navigate to="/UnAuthorized" replace />;
  }
  return children;
};

export default ProtectedRoute;

